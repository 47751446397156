/* eslint-disable new-cap */
import classNames from 'classnames'
import { ErrorMessage } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useRef } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { getIn } from 'formik'

import { SETTINGS } from '../../../../selectors'
import HelpText from './../../HelpText'
import Label from './Label'


const LocalCurrencyInput = props => {
  const { value, name } = props.field
  const { defaultValue } = props
  const [ val, setVal ] = useState(value || null)
  const [ isFocused, setIsFocused ] = useState(false)
  const [ needsUpdate, setNeedsUpdate ] = useState(false)

  const currency = useRef(
    new Intl.NumberFormat('en-ZA',
      {
        style: 'currency',
        currency: props.currency
      }).formatToParts(1).find(x => x.type === 'currency')
  )

  const symbol = currency.current

  useEffect(() => {
    setNeedsUpdate(true)
  }, [ isFocused ])

  useEffect(() => {
    if (value && !val || (val && value && (!isFocused || needsUpdate))) {
      setVal(value)
      setNeedsUpdate(false)
    } else if (val && !value) {
      setVal(null)
    }
  }, [ value, isFocused, needsUpdate ])

  useEffect(() => {
    if (defaultValue && defaultValue !== val) {
      setVal(defaultValue)
    }
  }, [ defaultValue ])

  useEffect(() => {
    if (!defaultValue && ![ undefined ].includes(props._value) && !val) {
      setVal(![ undefined, null ].includes(props._value) ? props._value : '')
    } else if (
      !isFocused &&
      ![ undefined, null ].includes(props._value) &&
      parseFloat(val) !== parseFloat(props._value)
    ) {
      setVal(![ undefined, null ].includes(props._value) ? props._value : '')
    }
  }, [ props._value, isFocused ])

  useEffect(() => {
    if ([ undefined, '' ].includes(val) || isNaN(val)) { // is empty, undefined or not a number (null is allowed)
      props.form.setFieldValue(name, null, false)
    } else if (val !== value && parseFloat(val) !== parseFloat(value) && (!isNaN(val) || val === null)) { // has changed, is a valid number or null
      props.form.setFieldValue(name, val, false)
    }
  }, [ val ])

  const { label, className, classes, disabled, id, suffix, actions, field, form } = props

  return (
    <div id={id} className={`form-group ${name} ${classes}`}>
      {label &&
        <Label htmlFor={name}>
          {label}
        </Label>
      }
      <div className={classNames('forminput', 'currency', className)}>
        {symbol && <div className="input-group-addon btn btn-icon-24 btn-none">{symbol.value}</div>}
        <NumberFormat
          className={classNames('form-control', { 'input-group-prefix': symbol, 'input-group-suffix': suffix })}
          decimalScale={2}
          thousandSeparator={' '}
          value={val}
          name={`${name}formatted`}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false)
            form.setFieldTouched(name, true).then(() => {
              if (props.onBlur && actions && getIn(actions, props.onBlur)) {
                actions[props.onBlur]({ field: field, form: form })
              }
            })
          }}
          id={`${name}-formatted`}
          onValueChange={values => {
            if (!values.value) { values.value = null }
            if (parseFloat(val) !== parseFloat(values.value) && !isNaN(values.value)) {
              setVal(values.value)
            }
          } }
          allowNegative={false}
          isNumericString
          allowEmptyFormatting={false}
          type='tel'
          disabled={disabled}
        />

        <input
          id={name}
          name={name}
          type="hidden"
          value={[ null, undefined, '' ].includes(value) ? '' : value}
        />
        {suffix}
        {props.help &&
            <span className="help-text">
              {props.help.map(component => (<HelpText {...component} key={`input-${name}-help`} />))}
            </span>
        }
        <ErrorMessage name={name} render={msg => <div className="error">{msg.replace(name, label)}</div> } />
      </div>
    </div>
  )
}

LocalCurrencyInput.propTypes = {
  id: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.number,
  _value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  actions: PropTypes.object,
  settings: PropTypes.object,
  currency: PropTypes.string,
  help: PropTypes.array,
  onBlur: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]),
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}


const mapStateToProps = state => {
  const settings = SETTINGS(state)
  return ({
    currency: settings.get('default_currency')
  })
}

const CurrencyInput = connect(mapStateToProps, null)(withImmutablePropsToJS(LocalCurrencyInput))

export default CurrencyInput
