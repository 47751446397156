/* eslint-disable new-cap */
import merge from 'deepmerge'
import { Field, Formik, getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import templateconfig from '../../../../config/template.json'
import { CACHE, PERMISSIONS, SETTINGS, SITE, MINUSER } from '../../../../selectors'
import { handleSubmitError, uniqueArray, reorder, isConditional } from '../../../../utils'
import { Button } from '../../../ui/Button'
import SimpleTable from '../../simpletable/SimpleTable'
import CustomForm from '../CustomForm'
import Card from '../../Card'
import FieldGroup from '../FieldGroup'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import TemplateEditSidebar from '../../../../containers/TemplateEditSidebar'
import TextInput from './Text'


class TemplateManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      brochure_term: '',
      reports_term: '',
      valuations_term: '',
      signatures_term: '',
      marketing_email_term: '',
      fields: templateconfig.config.fields,
      errors: {},
      touched: {}
    }
    this.isConditional = isConditional.bind(this)
    this.updateInput = this.updateInput.bind(this)
    this.setFieldValue = this.setFieldValue.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
    this.dragEnded = this.dragEnded.bind(this)
    this.fetchTemplates = this.fetchTemplates.bind(this)
    this.addVerb = this.addVerb.bind(this)
    this.createTemplate = this.createTemplate.bind(this)
    this.deleteTemplate = this.deleteTemplate.bind(this)
  }

  fetchTemplates({ params, resolve, reject }) {
    const { fetchMany } = this.props
    const values = {
      modelname: 'templates',
      conflicts: true,
      params
    }
    return fetchMany({ values, resolve, reject })
  }

  editTemplate(data, list) {
    this.setState({ edit: data, list }, () => {
      this.toggleEdit()
    })
  }

  createTemplate(values, actions) {
    const { createModel } = this.props
    return new Promise((resolve, reject) => {
      Object.keys(values).forEach(k => {
        const field = this.props.config.fields.find(f => f.name === k)
        if (field) {
          if (field.input === 'Float') { values[k] = values[k] ? parseFloat(values[k]).toFixed(1) : values[k] }
          if (field.name === 'fields') { values[k] = JSON.parse(values[k]) }
        }
      })
      values.modelname = 'templates'
      createModel({ values, resolve, reject })
    }).then(() => {
      if (getIn(this, values.template_type)) {
        getIn(this, values.template_type).refreshPage()
      }
      actions.resetForm({
        values: {
          id: '',
          name: '',
          display_name: '',
          template_type: '',
          model: '',
          fields: '',
          preview: ''
        }
      })
    }).catch(e => {
      handleSubmitError(e, actions, this.form)
    })
  }

  deleteTemplate(template, callback) {
    const { deleteModel } = this.props
    return new Promise((resolve, reject) => {
      const values = {
        modelname: 'templates',
        selected: [ template.id ]
      }
      // eslint-disable-next-line no-alert
      const confirmation = confirm('Are you sure you want to delete this template?')
      if (confirmation) {
        return deleteModel({ values, resolve, reject })
      }
      return reject('')
    }).then(r => {
      callback(r)
    })
  }

  toggleEdit() {
    this.props.toggleWideSidebar('show-template-manager')
  }

  updateInput(e) {
    this.setState({ term: e.target.value })
  }

  setFieldValue(name, value) {
    const state = merge({}, this.state)
    state[name] = value
    this.setState(state)
  }

  dragEnded({ oldIndex, newIndex, data, callback }) {
    const { bulkEditModel } = this.props
    if (oldIndex !== newIndex) {
      let results = uniqueArray(data, 'id')
      results = reorder(results, oldIndex, newIndex)
      results = results.map((r, idx) => {
        r.ordinal = idx
        return r
      })
      const values = {
        modelname: 'templates',
        toarray: true,
        noloader: true,
        nonotify: true,
        ...results
      }
      callback(results)
      new Promise((resolve, reject) => bulkEditModel(({ values, resolve, reject }))).then(r => {
        callback(r)
      }).catch(e => {
        callback(data)
        handleSubmitError(e)
      })
    }
  }


  addVerb() {
    const { fields } = this.props.config
    if (fields) {
      const report_fields = fields.filter(field => field.edit).map((field, ind) => ({
        ...field,
        name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
        key: `adv-${field.name}-${ind}`,
        edit: true,
        updateTemplates: field.name === 'template' ? this.updateTemplates : null
      }))
      return <FieldGroup
        card={false}
        match={this.props.match}
        groupname="Record Details"
        config={{
          fields
        }}
        fields={report_fields}
      />
    }
    return null
  }

  render() {
    const { cache, user } = this.props
    const settings = getIn(cache, `settings.${user.agent.site.id}`)
    return (
      <div className="template-manager">
        <Card
          header={
            <h3>Add New Template</h3>
          }
          background
          body={
            <Formik
              initialValues={{}}
              enableReinitialize={true}
              onSubmit={this.createTemplate}
            >{formik => (
                <CustomForm
                  component="div"
                  onChange={() => {
                    this.setState({ ...formik.values })
                  }}
                  render={() => (
                    <div className="search-fields">
                      {this.addVerb(formik)}

                      <div className="search-buttons" style={{ paddingRight: 0 }}>
                        <Button
                          id="keyword-search-btn"
                          tabIndex="-1"
                          type="button"
                          icon="#icon24-Plus"
                          onClick={formik.submitForm}
                          disabled={this.state.searching}
                          className="btn btn-grey btn-icon-16 btn-icon-left"
                        >
                          Add Template
                        </Button>
                      </div>
                    </div>
                  )}
                />
              )}
            </Formik>
          }
        />
        <Card
          header={
            <h3>Brochure Templates</h3>
          }
          background
          body={
            <Formik
              initialValues={{
                template_type: 'brochure'
              }}
              enableReinitialize={true}
              onSubmit={values => {
                this.setState({ brochure_term: values.term })
              }}
            >{formik => (
                <>
                  <div className="templates-search">
                    <CustomForm
                      component="div"
                      render={() => (
                        <div className="search-fields">
                          <div className="field col-lg-4">
                            <Field
                              id="brochure-search"
                              name="term"
                              placeholder="Keyword Search"
                              component={TextInput}
                              className="term"
                              bounce={true}
                              show_search
                              suffix={<Button icon="#icon24-Search" type="button" disabled={this.state.searching_brochures} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                            />
                          </div>
                          {this.state.brochure_term &&
                            <div className="reset-group">
                              <strong>This list is filtered</strong>
                              <Button
                                id="keyword-search-btn"
                                tabIndex="-1"
                                type="button"
                                icon="#icon16-Refresh"
                                onClick={() => {
                                  formik.setFieldValue('term', '')
                                  formik.setFieldTouched('term', true)
                                  formik.submitForm()
                                }}
                                disabled={this.state.searching_brochures}
                                className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                              >
                                Reset
                              </Button>
                            </div>
                          }
                        </div>
                      )}
                    />
                  </div>
                  <div className="templatesbody">
                    <SimpleTable
                      user={user}
                      config={this.props.config}
                      header={[
                        {
                          label: 'Default',
                          name: 'front_end',
                          input: 'Check',
                          classes: [ 'text-left' ],
                          orderable: false,
                          edit: true,
                          table_edit: true
                        },
                        {
                          label: 'Name',
                          name: 'display_name',
                          orderable: false
                        },
                        {
                          label: 'Category',
                          name: 'meta.category.name',
                          orderable: false,
                          orderby: 'category__name'
                        },
                        {
                          label: 'Listing Type',
                          name: 'model',
                          orderable: false
                        },
                        {
                          label: 'ID',
                          name: 'name',
                          orderable: false
                        }
                      ]}
                      getClass={el => {
                        this.brochure = el
                      }}
                      updateModel={({ values, resolve, reject, data, callback }) => {
                        const results = data.map(r => ({ ...r }))
                        this.props.updateModel({ values, resolve, reject })
                        results.forEach(d => {
                          if (d.front_end) {
                            d.front_end = false
                          }
                        })
                        results.forEach(d => {
                          if (d.id === values.id) {
                            d.front_end = values.front_end
                          }
                        })
                        callback(results)
                      }}
                      action={this.fetchTemplates}
                      params={{
                        template_type: 'brochure',
                        term: this.state.brochure_term,
                        meta_fields: [ 'category' ],
                        get_all: 1
                      }}
                      parser={response => {
                        const data = {
                          options: response
                        }
                        return data
                      }}
                      sortable
                      dragEnded={this.dragEnded}
                      rowActions={(row, data) => (
                        <>
                          <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.editTemplate(data, this.brochure)} title="Edit Template" type="button" />
                          {data.status !== 'Inactive' ? (
                            <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.deleteTemplate(data, () => {
                              this.brochure.refreshPage()
                            })} title="Archive Brochure" type="button" />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
            </Formik>
          }
        />
        <Card
          header={
            <h3>Report Templates</h3>
          }
          background
          body={
            <Formik
              initialValues={{
                template_type: 'report'
              }}
              enableReinitialize={true}
              onSubmit={(values, form) => {
                this.setState({ reports_term: values.term })
                form.setTouched({})
              }}
            >{formik => (
                <>
                  <div className="templates-search">
                    <CustomForm
                      component="div"
                      render={el => (
                        <div className="search-fields">
                          <div className="field col-lg-4">
                            <Field
                              id="report-search"
                              name="term"
                              placeholder="Keyword Search"
                              component={TextInput}
                              className="term"
                              bounce={true}
                              show_search
                              form_el={el}
                              suffix={<Button icon="#icon24-Search" type="button" disabled={this.state.searching_reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                            />
                          </div>
                          {this.state.reports_term &&
                            <div className="reset-group">
                              <strong>This list is filtered</strong>
                              <Button
                                id="keyword-search-btn"
                                tabIndex="-1"
                                type="button"
                                icon="#icon16-Refresh"
                                onClick={() => {
                                  formik.setFieldValue('term', '')
                                  formik.setFieldTouched('term', true)
                                  formik.submitForm()
                                }}
                                disabled={this.state.searching_reports}
                                className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                              >
                                Reset
                              </Button>
                            </div>
                          }
                        </div>
                      )}
                    />
                  </div>
                  <div className="templatesbody">
                    <SimpleTable
                      user={user}
                      config={this.props.config}
                      header={[
                        {
                          label: 'Name',
                          name: 'display_name',
                          orderable: false
                        },
                        {
                          label: 'Category',
                          name: 'meta.category.name',
                          orderable: false,
                          orderby: 'category__name'
                        },
                        {
                          label: 'Listing Type',
                          name: 'model',
                          orderable: false
                        },
                        {
                          label: 'ID',
                          name: 'name',
                          orderable: false
                        }
                      ]}
                      getClass={el => {
                        this.report = el
                      }}
                      updateModel={({ values, resolve, reject, data, callback }) => {
                        const results = data.map(r => ({ ...r }))
                        this.props.updateModel({ values, resolve, reject })
                        results.forEach(d => {
                          if (d.front_end) {
                            d.front_end = false
                          }
                        })
                        results.forEach(d => {
                          if (d.id === values.id) {
                            d.front_end = values.front_end
                          }
                        })
                        callback(results)
                      }}
                      action={this.fetchTemplates}
                      params={{
                        template_type: 'report',
                        term: this.state.reports_term,
                        meta_fields: [ 'category' ],
                        get_all: 1
                      }}

                      parser={response => {
                        const data = {
                          options: response
                        }
                        return data
                      }}
                      sortable
                      dragEnded={this.dragEnded}
                      rowActions={(row, data) => (
                        <>
                          <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.editTemplate(data, this.report)} title="Edit Template" type="button" />
                          {data.status !== 'Inactive' ? (
                            <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.deleteTemplate(data, () => {
                              this.report.refreshPage()
                            })} title="Archive Report" type="button" />
                          ) : null}
                        </>
                      )}
                    />
                  </div>
                </>
              )}
            </Formik>
          }
        />
        {settings.valuation_addon ? (
          <Card
            header={
              <h3>Valuation Templates</h3>
            }
            background
            body={
              <Formik
                initialValues={{
                  template_type: 'valuation',
                  model__in: [ 'valuation' ]
                }}
                enableReinitialize={true}
                onSubmit={values => {
                  this.setState({ valuations_term: values.term })
                }}
              >{formik => (
                  <>
                    <div className="templates-search">
                      <CustomForm
                        component="div"
                        render={el => (
                          <div className="search-fields">
                            <div className="field col-lg-4">
                              <Field
                                id="report-search"
                                name="term"
                                placeholder="Keyword Search"
                                component={TextInput}
                                className="term"
                                bounce={true}
                                show_search
                                form_el={el}
                                suffix={<Button icon="#icon24-Search" type="button" disabled={this.state.searching_reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                              />
                            </div>
                            {this.state.valuations_term &&
                              <div className="reset-group">
                                <strong>This list is filtered</strong>
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon16-Refresh"
                                  onClick={() => {
                                    formik.setFieldValue('term', '')
                                    formik.setFieldTouched('term', true)
                                    formik.submitForm()
                                  }}
                                  disabled={this.state.searching_reports}
                                  className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                                >
                                  Reset
                                </Button>
                              </div>
                            }
                          </div>
                        )}
                      />
                    </div>
                    <div className="templatesbody">
                      <SimpleTable
                        user={user}
                        config={this.props.config}
                        header={[
                          {
                            label: 'Name',
                            name: 'display_name',
                            orderable: false
                          },
                          {
                            label: 'Listing Type',
                            name: 'model',
                            orderable: false
                          },
                          {
                            label: 'ID',
                            name: 'name',
                            orderable: false
                          }
                        ]}
                        getClass={el => {
                          this.valuation = el
                        }}
                        updateModel={({ values, resolve, reject, data, callback }) => {
                          const results = data.map(r => ({ ...r }))
                          this.props.updateModel({ values, resolve, reject })
                          results.forEach(d => {
                            if (d.front_end) {
                              d.front_end = false
                            }
                          })
                          results.forEach(d => {
                            if (d.id === values.id) {
                              d.front_end = values.front_end
                            }
                          })
                          callback(results)
                        }}
                        action={this.fetchTemplates}
                        params={{
                          template_type: 'valuation',
                          model__in: [ 'valuation' ],
                          term: this.state.valuations_term,
                          meta_fields: [ 'category' ],
                          get_all: 1
                        }}
                        parser={response => {
                          const data = {
                            options: response
                          }
                          return data
                        }}
                        sortable
                        dragEnded={this.dragEnded}
                        rowActions={(row, data) => (
                          <>
                            <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.editTemplate(data, this.valuation)} title="Edit Template" type="button" />
                            {data.status !== 'Inactive' ? (
                              <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.deleteTemplate(data, () => {
                                this.valuation.refreshPage()
                              })} title="Archive Valuation" type="button" />
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                  </>
                )}
              </Formik>
            }
          />
        ) : null}
        {settings.email_stationery_addon ? (
          <Card
            header={
              <h3>Signature Templates</h3>
            }
            background
            body={
              <Formik
                initialValues={{
                  template_type: 'signature',
                  model__in: [ 'agent' ]
                }}
                enableReinitialize={true}
                onSubmit={values => {
                  this.setState({ signatures_term: values.term })
                }}
              >{formik => (
                  <>
                    <div className="templates-search">
                      <CustomForm
                        component="div"
                        render={el => (
                          <div className="search-fields">
                            <div className="field col-lg-4">
                              <Field
                                id="report-search"
                                name="term"
                                placeholder="Keyword Search"
                                component={TextInput}
                                className="term"
                                bounce={true}
                                show_search
                                form_el={el}
                                suffix={<Button icon="#icon24-Search" type="button" disabled={this.state.searching_reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                              />
                            </div>
                            {this.state.signatures_term &&
                              <div className="reset-group">
                                <strong>This list is filtered</strong>
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon16-Refresh"
                                  onClick={() => {
                                    formik.setFieldValue('term', '')
                                    formik.setFieldTouched('term', true)
                                    formik.submitForm()
                                  }}
                                  disabled={this.state.searching_reports}
                                  className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                                >
                                  Reset
                                </Button>
                              </div>
                            }
                          </div>
                        )}
                      />
                    </div>
                    <div className="templatesbody">
                      <SimpleTable
                        user={user}
                        config={this.props.config}
                        header={[
                          {
                            label: 'Name',
                            name: 'display_name',
                            orderable: false
                          },
                          {
                            label: 'Listing Type',
                            name: 'model',
                            orderable: false
                          },
                          {
                            label: 'ID',
                            name: 'name',
                            orderable: false
                          }
                        ]}
                        getClass={el => {
                          this.signature = el
                        }}
                        updateModel={({ values, resolve, reject, data, callback }) => {
                          const results = data.map(r => ({ ...r }))
                          this.props.updateModel({ values, resolve, reject })
                          results.forEach(d => {
                            if (d.front_end) {
                              d.front_end = false
                            }
                          })
                          results.forEach(d => {
                            if (d.id === values.id) {
                              d.front_end = values.front_end
                            }
                          })
                          callback(results)
                        }}
                        action={this.fetchTemplates}
                        params={{
                          template_type: 'signature',
                          model__in: [ 'agent' ],
                          term: this.state.signatures_term,
                          meta_fields: [ 'category' ],
                          get_all: 1
                        }}
                        parser={response => {
                          const data = {
                            options: response
                          }
                          return data
                        }}
                        sortable
                        dragEnded={this.dragEnded}
                        rowActions={(row, data) => (
                          <>
                            <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.editTemplate(data, this.signature)} title="Edit Template" type="button" />
                            {data.status !== 'Inactive' ? (
                              <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.deleteTemplate(data, () => {
                                this.signature.refreshPage()
                              })} title="Archive Signature" type="button" />
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                  </>
                )}
              </Formik>
            }
          />
        ) : null}
        {settings.bulk_mailing_addon ? (
          <Card
            header={
              <h3>Marketing Email Templates</h3>
            }
            background
            body={
              <Formik
                initialValues={{
                  template_type: 'marketing-email',
                  model__in: [ 'residential', 'commercial', 'holiday', 'project' ]
                }}
                enableReinitialize={true}
                onSubmit={values => {
                  this.setState({ marketing_email_term: values.term })
                }}
              >{formik => (
                  <>
                    <div className="templates-search">
                      <CustomForm
                        component="div"
                        render={el => (
                          <div className="search-fields">
                            <div className="field col-lg-4">
                              <Field
                                id="report-search"
                                name="term"
                                placeholder="Keyword Search"
                                component={TextInput}
                                className="term"
                                bounce={true}
                                show_search
                                form_el={el}
                                suffix={<Button icon="#icon24-Search" type="button" disabled={this.state.searching_reports} onClick={formik.submitForm} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />}
                              />
                            </div>
                            {this.state.marketing_email_term &&
                              <div className="reset-group">
                                <strong>This list is filtered</strong>
                                <Button
                                  id="keyword-search-btn"
                                  tabIndex="-1"
                                  type="button"
                                  icon="#icon16-Refresh"
                                  onClick={() => {
                                    formik.setFieldValue('term', '')
                                    formik.setFieldTouched('term', true)
                                    formik.submitForm()
                                  }}
                                  disabled={this.state.searching_reports}
                                  className="input-group-addon btn btn-grey btn-icon-16 btn-icon-left"
                                >
                                  Reset
                                </Button>
                              </div>
                            }
                          </div>
                        )}
                      />
                    </div>
                    <div className="templatesbody">
                      <SimpleTable
                        user={user}
                        config={this.props.config}
                        header={[
                          {
                            label: 'Name',
                            name: 'display_name',
                            orderable: false
                          },
                          {
                            label: 'Listing Type',
                            name: 'model',
                            orderable: false
                          },
                          {
                            label: 'ID',
                            name: 'name',
                            orderable: false
                          }
                        ]}
                        getClass={el => {
                          this.signature = el
                        }}
                        updateModel={({ values, resolve, reject, data, callback }) => {
                          const results = data.map(r => ({ ...r }))
                          this.props.updateModel({ values, resolve, reject })
                          results.forEach(d => {
                            if (d.front_end) {
                              d.front_end = false
                            }
                          })
                          results.forEach(d => {
                            if (d.id === values.id) {
                              d.front_end = values.front_end
                            }
                          })
                          callback(results)
                        }}
                        action={this.fetchTemplates}
                        params={{
                          template_type: 'marketing-email',
                          model__in: [ 'residential', 'commercial', 'holiday', 'project' ],
                          term: this.state.marketing_email_term,
                          meta_fields: [ 'category', 'preview' ],
                          get_all: 1
                        }}
                        parser={response => {
                          const data = {
                            options: response
                          }
                          return data
                        }}
                        sortable
                        dragEnded={this.dragEnded}
                        rowActions={(row, data) => (
                          <>
                            <Button icon="#icon16-Edit" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.editTemplate(data, this.signature)} title="Edit Template" type="button" />
                            {data.status !== 'Inactive' ? (
                              <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => this.deleteTemplate(data, () => {
                                this.signature.refreshPage()
                              })} title="Archive Signature" type="button" />
                            ) : null}
                          </>
                        )}
                      />
                    </div>
                  </>
                )}
              </Formik>
            }
          />
        ) : null}
        <WideSidebar sidebar={'show-template-manager'}>
          <TemplateEditSidebar
            key="template-manager-edit"
            toggleLookup={this.toggleEdit}
            template={this.state.edit}
            config={this.props.config}
            list={this.state.list}
            match={this.props.match}
          />
        </WideSidebar>
      </div>
    )
  }
}

TemplateManager.propTypes = {
  user: PropTypes.object,
  cache: PropTypes.object,
  fields: PropTypes.object,
  config: PropTypes.object,
  match: PropTypes.object,
  updatetemplate: PropTypes.func,
  deletetemplate: PropTypes.func,
  modelname: PropTypes.string,
  fetchMany: PropTypes.func,
  deleteModel: PropTypes.func,
  toggleWideSidebar: PropTypes.func,
  createModel: PropTypes.func,
  updateModel: PropTypes.func,
  bulkEditModel: PropTypes.func
}

const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  let modelname = ownProps.modelname ? ownProps.modelname : null
  let modelid = ownProps.modelid ? ownProps.modelid : null

  if (ownProps.match && ownProps.match.params) {
    modelname = modelname ? modelname : ownProps.match.params.model
    modelid = modelid ? modelid : ownProps.match.params.id
  }

  const site = SITE(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const user = MINUSER(state)
  const permissions = PERMISSIONS(state)
  const branches_allowed = user.getIn([ 'agent', 'branches_allowed' ])

  // Minimize user
  const agent = Map({ id: user.getIn([ 'agent', 'id' ]), site, branches_allowed })
  const minuser = Map({
    permissions,
    agent
  })

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    if (ownProps.match) {
      mincache = mincache.mergeDeepIn([ modelname, modelid ], cache.getIn([ modelname, modelid ])) // We need the current model data in cache too
    } else { // Pass entire cache if no id
      mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
    }
  }
  templateconfig.config.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }
    if (field.caches) {
      field.caches.forEach(mn => {
        mincache[mn] = cache.get(mn)
      })
    }
    if (field.input === 'LocationSelect') { mincache.set('branches', cache.get('branches')) }
  })

  return {
    cache: mincache,
    user: minuser
  }
}


export default connect(mapStateToProps, null)(withImmutablePropsToJS(TemplateManager))
