/* eslint-disable new-cap */
import merge from 'deepmerge'
import { getIn } from 'formik'
import { hasIn, List, Map } from 'immutable'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import * as actions from '../../../actions'
import { ADDONS, CACHE, CONFIG, CONFIGS, SELECTED, SETTINGS, SITE, FIELDGROUP_USER, LOCATION } from '../../../selectors'
import { isConditional, parseClasses, convertArrayToObject, parseURL, hasPermission } from '../../../utils'
import Card from '../Card'
import FieldComponent from './FieldComponent'
import { formikConnect } from './customFormikConnect'


class FieldGroup extends React.Component {
  constructor(props) {
    super(props)
    this.isConditional = isConditional.bind(this)
    this.state = {
      /*
        * Create a watched fields object for this group based on
        * initial static related fields and other dynamically related fields
        * in order to reduce re-renders and speed up large forms.
      */
      watched: [],
      overwritten: {},
      fields: [],
      modelname: props.modelname,
      areas: null,
      locations: null
    }
    this.getWatched = this.getWatched.bind(this)
    this.getFields = this.getFields.bind(this)
    this.containsWebEdit = this.containsWebEdit.bind(this)
    this.containsRequired = this.containsRequired.bind(this)
    this.containsQuality = this.containsQuality.bind(this)
    this.containsEditable = this.containsEditable.bind(this)
    this.fieldClasses = this.fieldClasses.bind(this)
    this.renderField = this.renderField.bind(this)
    this.renderFieldGroup = this.renderFieldGroup.bind(this)
  }

  componentDidMount() {
    if (getIn(this.props, 'match.params.model') === 'location-profiles' && this.props.fields.some(f => f.input === 'LocationSelect')) {
      new Promise((resolve, reject) => this.props.actions.fetchLocations({ values: { modelname: 'listings', params: { status__in__not: [ 'valuation' ] } }, resolve, reject }))
        .then(results => {
          let { areas, locations } = results
          areas = convertArrayToObject(areas)
          locations = convertArrayToObject(locations)
          this.setState({ areas, locations })
        })
    }
    const fields = this.getFields(this.props)
    if (!isEqual(this.state.fields, fields)) {
      this.setState({ fields })
    }
    const watched = this.getWatched(this.props, this.state)
    if (!isEqual(this.state.watched, watched)) {
      this.setState({ watched })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.modelname !== nextProps.modelname) {
      return true
    }
    if (this.props.classes !== nextProps.classes) {
      return true
    }
    if (!isEqual(this.props.match, nextProps.match)) {
      return true
    }
    if (!isEqual(this.props.location, nextProps.location)) {
      return true
    }
    if (!isEqual(this.props.fields, nextProps.fields)) {
      return true
    }
    if (!isEqual(this.props.config, nextProps.config)) {
      return true
    }
    if (this.props.buttons) {
      return true
    }
    const newwatched = this.getWatched(nextProps, nextState)
    if (!isEqual(this.state.watched, newwatched)) {
      return true
    }
    const fields = this.getFields(nextProps)
    const prevValues = fields.map(f => getIn(this.props.form.values, f.name))
    const nextValues = fields.map(f => getIn(nextProps.form.values, f.name))
    if (!isEqual(prevValues, nextValues)) {
      return true
    }
    const prevTouched = fields.map(f => getIn(this.props.form.touched, f.name))
    const nextTouched = fields.map(f => getIn(nextProps.form.touched, f.name))
    if (!isEqual(prevTouched, nextTouched)) {
      return true
    }
    if (!isEqual(this.props.form.status, nextProps.form.status)) {
      return true
    }
    return false
  }

  componentDidUpdate(prevProps) {
    const fields = this.getFields(this.props)
    if (!isEqual(fields, this.state.fields)) {
      this.setState({ fields })
    }
    if (this.props.modelname !== prevProps.modelname) {
      this.setState({ watched: [], modelname: this.props.modelname })
    } else {
      const watched = this.getWatched(this.props, this.state)
      if (!isEqual(this.state.watched, watched)) {
        const overwritten = merge({}, this.state.overwritten)
        this.props.fields.forEach(field => {
          if (getIn(prevProps.form.values, field.name) !== undefined && !this.isConditional(field, 'edit', false, this.props.form, this.props.user, this.props.cache)) {
            overwritten[field.name] = getIn(prevProps.form.values, field.name)
          } else if (getIn(this.props, 'match.params.action') === 'add' && getIn(field, 'defaultvalue')) {
            if (![ null, undefined ].includes(field.defaultvalue)) {
              if (field.defaultvalue.replace) {
                overwritten[field.name] = parseURL(field.defaultvalue, { agent: this.props.user.agent })
              } else {
                overwritten[field.name] = field.defaultvalue
              }
              if (field.defaultvalue === 'Square Metres' && !this.props.addons.includes('metric_units')) {
                overwritten[field.name] = 'Square Feet'
              }
            }
          }
        })
        this.setState({ watched, overwritten }, () => {
          const { config } = this.props
          const { touched, values } = this.props.form
          const unsetvals = {}
          const newvals = merge({}, this.props.form.values)
          const reapplyvals = {}
          this.state.watched.forEach(field => {
            if ( // We need to unset fields that are no longer editable but have values in the formik state
              Object.keys(touched).length > 0 && // We've been touched
              field._edit === 'false' && // No editing of this field
              field.group !== 'Publish' && // No portal fields should be affected ever
              ![ null, undefined ].includes(values[field.name]) && // Field has a value in formik state
              config.fields.filter(cf => cf.name === field.name).length === 1) { // No dupe named fields
              unsetvals[field.name] = null
              delete newvals[field.name]
            }
            if ( // Need to reassign form values if editable __again__ and already set in group state
              Object.keys(touched).length > 0 && // We've been touched
              field._edit === 'true' && // Allow editing of this field
              field.group !== 'Publish' && // No portal fields should be affected ever
              [ null, undefined ].includes(values[field.name]) && // Field DOESN'T have a value in formik state
              overwritten[field.name] && // Field has a value in the group's state
              config.fields.filter(cf => cf.name === field.name).length === 1) { // No dupe named fields
              reapplyvals[field.name] = overwritten[field.name]
              newvals[field.name] = overwritten[field.name]
            }
          })
          // Unset any uneditable fields in formik or else reapply any editable overwritten fields with their old values
          if (Object.keys(unsetvals).length > 0 || Object.keys(reapplyvals).length > 0) {
            this.props.form.setValues(newvals, false)
          }
        })
      }
    }
  }

  getWatched(props, state) {
    if (!props.form) {
      return []
    }
    return state.fields.filter(field => field.edit).map(field => {
      const field_name = field.parent ? `${field.parent}.${field.name}` : field.name
      const touched = getIn(props.form.touched, field_name)
      const f = {
        ...field,
        // Watched field immutables which should not change
        classes: field.classes ? parseClasses(field.classes, props.form.values) : '',
        collapsed: props.collapsed,
        required: props.required,
        quality: props.quality,
        webedit: props.webedit,
        touched,
        _disabled: this.isConditional(field, 'disabled', false, props.form, props.user, props.cache).toString(),
        _edit: this.isConditional(field, 'edit', false, props.form, props.user, props.cache).toString(),
        _required: this.isConditional(field, 'required', false, props.form, props.user, props.cache).toString(),
        _webedit: this.isConditional(field, 'webedit', false, props.form, props.user, props.cache).toString(),
        _quality: this.isConditional(field, 'quality', false, props.form, props.user, props.cache).toString(),
        _value: getIn(props.form.values, field_name),
        defaultValue: getIn(props.form.initialValues, field_name),
        _errors: props.form.errors,
        _areas: !!state.areas,
        _suburbs: !!state.locations
      }
      if (field.modelname) {
        if (!f.cache) {
          f.cache = {}
        }
        f.cache[field.modelname] = props.cache[field.modelname]
      }
      if (field.fields) {
        field.fields.forEach(fafield => { // Field arrays
          if (fafield.modelname) {
            if (!f.cache) {
              f.cache = {}
            }
            f.cache[fafield.modelname] = props.cache[fafield.modelname]
          }
        })

        // Populate the child fields if it is a ParkingRatio component:
        if (field.input === 'ParkingRatio') {
          field.fields.forEach(fafield => {
            if (!fafield._value) {
              fafield._value = getIn(props.form.values, fafield.name)
            }
            if (!fafield.defaultValue) {
              fafield.defaultValue = getIn(props.form.initialValues, fafield.name)
            }
          })
        }
      }
      if ([ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetSelector' ].includes(field.input)) {
        // These components rely on meta data that needs to trigger updates as well
        const { values } = props.form
        if (values.id && props.cache[props.config.modelname][values.id] &&
          props.cache[props.config.modelname][values.id].meta &&
          props.cache[props.config.modelname][values.id].meta[field_name]) {
          if (Array.isArray(values[field_name]) && values.id) { // Edit mode and value is array
            values[field_name].forEach(() => {
              f.meta = merge({}, props.cache[props.config.modelname][values.id].meta[field_name])
            })
          } else if (values.id) { // Populate meta for ImageCrop
            f.meta = merge({}, props.cache[props.config.modelname][values.id].meta[field_name])
          }
        } else if ([ 'images', 'documents' ].includes(props.config.modelname)) {
          if (props.cache[props.config.modelname][values.id]) {
            if (!f.meta) {
              f.meta = []
            }
            f.meta.push(props.cache[props.config.modelname][values.id])
          }
        }
      }
      if (field.caches) {
        if (!f.cache) {
          f.cache = {}
        }
        field.caches.forEach(k => {
          f.cache[k] = getIn(props.cache, k)
        })
      }
      if (field.watch) {
        f._related = field.watch.map(val => {
          const wfield = props.config.fields.find(fe => fe.name === val)
          if (wfield) {
            const value = getIn(props.form.values, val)
            const cache = {}
            if (Array.isArray(value)) {
              value.forEach(v => {
                cache[v] = getIn(props.cache, `${wfield.modelname}.${v}`)
              })
            } else if (value) {
              cache[value] = getIn(props.cache, `${wfield.modelname}.${value}`)
            }
            return { value, cache }
          }
          return null
        })
      }
      if ([ 'TextNotes', 'CheckNotes', 'SelectNotes' ].includes(field.input)) {
        f._related = f._related || []
        const note_value = getIn(props.form.values, `${field.notes_name ? field.notes_name : `${field.name}_notes`}`)
        f._related.push({ value: note_value, cache: {} })
      }
      if (field.dependent) {
        f._dependent = getIn(props.form.values, field.dependent)
      }
      if (field.limit) {
        f._limit = props.form.status
      }
      return f
    })
  }

  getFields(props) {
    const { fields, groupname, user, model } = props
    const newfields = fields.filter(field =>
      field.group === groupname &&
        field.edit &&
        hasPermission(field.permissions, user.permissions)
    ).map(field => {
      if (field.group === 'Publish' && field.name.split('.').length > 2) {
        const parts = field.name.split('.')
        const meta = parts[0]
        const pslug = parts[1]
        if (meta === 'portals') {
          if (!getIn(props, 'active_portals', []).includes(pslug)) { // If portal is not active in agency settings, hide input
            field.edit = false
          }
        }
      }
      if (field.input === 'ContactLookup') {
        field.model = model
      }
      return field
    })
    return newfields
  }

  containsWebEdit() {
    return this.state.watched.find(field => field._webedit === 'true') !== undefined
  }

  containsRequired() {
    return this.state.watched.find(field => field._required === 'true') !== undefined
  }

  containsQuality() {
    return this.state.watched.find(field => field._quality === 'true') !== undefined
  }

  containsEditable() {
    return this.state.watched.find(field => {
      if (this.props.required) { return field._edit === 'true' && field._required === 'true' }
      if (this.props.quality) { return field._edit === 'true' && field._quality === 'true' }
      return field._edit === 'true'
    }) !== undefined
  }

  renderCopyAddress(fieldTo, fieldFrom) {
    return (
      <button
        id={`${this.props.creator}_same_as_physical_address`}
        name="same_as_physical_address"
        onClick={e => {
          e.preventDefault()
          const { form } = this.props
          const val = form.values[fieldFrom.name]
          form.setFieldValue(fieldTo.name, val).then(() => {
            document.getElementsByName(fieldTo.name)[0].value = val
            form.setFieldTouched(fieldTo.name)
          })
        }}
        className="btn btn-none link"
      >Copy to Postal Address</button>
    )
  }

  fieldClasses(field, errors, bulkedit) {
    const required = field._required === 'true' && !bulkedit
    const read_only = field._disabled === 'true' || field.readonly === true || this.isConditional(field, 'readonly', false, this.props.form, this.props.user, this.props.cache)
    return classNames({
      field: field.input !== 'FieldArray',
      required: required,
      disabled: read_only,
      quality: field._quality === 'true',
      error: (field.name in errors)
    }, field.cols ? ` col-${field.cols}` : ' col-lg-12')
  }

  renderField(props, key, field, values, errors, label = false) {
    /* Filter props so that we only pass what an input requires */
    // eslint-disable-next-line no-unused-vars
    const { fields, toggle, group, config, cache, ...newprops } = props
    newprops.modelname = props.config.modelname
    newprops.cache = {}
    newprops.setIgnoreFields = props.setIgnoreFields
    newprops.cache.settings = props.cache.settings // Insert the settings for extraparams lookups when parsing URLs
    const model_id = values.id || props.modelid
    if (model_id && newprops.defaultvalue) { delete newprops.defaultvalue } // No defaults required for edit
    const name = field.name.replace('override-', '')
    if (field.input === 'ResetButton') { return null }
    if (field.modelname || field.fields || [ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetSelector' ].includes(field.input)) { // Related model fields, fieldarrays and dropzones need cache
      if (props.cache[field.modelname] && ![ 'FileDropzone', 'ImageCrop', 'GallerySelector', 'AssetSelector' ].includes(field.input)) {
        newprops.cache[field.modelname] = props.cache[field.modelname]
      } else if (field.fields) { // Field Array
        field.fields.forEach(fafield => {
          if (fafield.modelname && props.cache[fafield.modelname]) {
            newprops.cache[fafield.modelname] = props.cache[fafield.modelname]
          }
        })
      } else if (model_id && (
        getIn(props, `cache.${props.config.modelname}.${model_id}.meta.${name}`))
      ) {
        if (Array.isArray(values[name]) && model_id) { // Edit mode and value is array
          values[name].forEach(() => {
            newprops.meta = merge({}, props.cache[props.config.modelname][model_id].meta[name])
          })
        } else if (model_id) { // Populate meta for ImageCrop
          newprops.meta = merge({}, props.cache[props.config.modelname][model_id].meta[name])
        }
      } else if ([ 'images', 'documents' ].includes(props.config.modelname)) {
        if (props.cache[props.config.modelname][model_id]) {
          if (!newprops.meta) {
            newprops.meta = []
          }
          newprops.meta.push(props.cache[props.config.modelname][model_id])
        }
      }
    }
    if (field.caches) {
      field.caches.forEach(modelname => {
        newprops.cache[modelname] = { ...this.props.cache[modelname] }
      })
    }

    if (this.state.areas && field.name === 'area') {
      newprops.force_filter = this.state.areas
    }

    if (this.state.locations && field.name === 'suburb') {
      newprops.force_filter = this.state.locations
    }

    if (props.match && field.usemeta && hasIn(props.cache, [ props.config.modelname, props.match.params.id, 'meta' ])) {
      newprops.cache.meta = { ...props.cache[props.config.modelname][props.match.params.id].meta }
    }
    newprops.error = getIn(errors, newprops.name)
    return <FieldComponent {...newprops} key={`field-${field.name}`} field={field} removeLabels={label} />
  }

  renderFieldGroup() {
    /*
     * This renders a group of fields on a card
     * so long as there are editable fields in the group.
    */
    const { errors, values, touched } = this.props.form
    const { gidx, bulkedit, config, groupname, group, classes } = this.props
    const { overwritten } = this.state
    const unsetvals = {}
    const newvals = merge({}, this.props.form.values)
    const reapplyvals = {}
    const outer = this.state.watched.filter(field => {
      if ( // We need to unset fields that are no longer editable but have values in the formik state
        Object.keys(touched).length > 0 && // We've been touched
        field._edit === 'false' && // No editing of this field
        field.group !== 'Publish' && // No portal fields should be affected ever
        getIn(values, field.name) && // Field has a value in formik state
        config.fields.filter(cf => cf.name === field.name).length === 1) { // No dupe named fields
        unsetvals[field.name] = null
        delete newvals[field.name]
      }
      if ( // Need to reassign form values if editable __again__ and already set in group state
        Object.keys(touched).length > 0 && // We've been touched
        field._edit === 'true' && // Allow editing of this field
        field.group !== 'Publish' && // No portal fields should be affected ever
        !getIn(values, field.name) && // Field DOESN'T have a value in formik state
        overwritten[field.name] && // Field has a value in the group's state
        config.fields.filter(cf => cf.name === field.name).length === 1) { // No dupe named fields
        reapplyvals[field.name] = overwritten[field.name]
        newvals[field.name] = overwritten[field.name]
      }

      if (this.props.webedit) {
        return field._edit === 'true' && field._webedit === 'true'
      }

      if (this.props.required) {
        return field._edit === 'true' && field._required === 'true'
      }

      if (this.props.quality) {
        return field._edit === 'true' && field._quality === 'true'
      }
      return field._edit === 'true'
    })
    const outer_hidden = outer.filter(field => field.input === 'Hidden').map(field => (
      <React.Fragment key={`hidden-${field.name}`}>{this.renderField(this.props, `fc-${field.name}`, field, values, errors)}</React.Fragment>
    ))
    const outer_visible = outer.filter(field => field.input !== 'Hidden').map((field, ind) => {
      const inner = []
      if (Array.isArray(field.name)) { // This is a composite field
        const compositeFields = this.props.config.fields.filter(subfield => field.name.includes(subfield.name))
        const sub = compositeFields.map(() =>
          this.renderField(this.props, `fc-${field.name}`, field, values, errors, true)).filter(node => node)
        const label = <label key={`label-${field.name}`} htmlFor={field.label}>{field.label}</label>
        inner.push(<div key={`field-${field.name}`} className='form-group grouped-fields'>{label}{sub}</div>)
      } else if (field.copy) { // This is a copyable field
        const fieldFrom = this.props.config.fields.find(f => field.copy === f.name)
        const input = this.renderField(this.props, `fc-${field.name}`, { ...field, prefix: this.renderCopyAddress(field, fieldFrom) }, values, errors)
        if (!input) { return null }
        inner.push(
          <div
            key={`field-${field.name}`}
            className={this.fieldClasses(field, errors, bulkedit)}
          >
            {input}
          </div>
        )
      } else { // A normal field component
        const input = this.renderField(this.props, `fc-${field.name}`, field, values, errors)
        if (!input) { return null }
        inner.push(
          <div
            key={`field-${field.name}`}
            className={this.fieldClasses(field, errors, bulkedit)}
          >
            {input}
          </div>
        )
      }
      if (this.props.buttons && (ind + 1) === outer.filter(f => f.input !== 'Hidden').length) {
        inner.push(this.props.buttons({
          group,
          gidx,
          groupname,
          classes
        }))
      }
      return inner
    }).filter(node => node)
    const groups = [ ...outer_hidden ]
    let current_group = []
    let count = 12
    let counter = 0
    outer_visible.forEach((node, idx) => {
      const node_clases = node.props && node.props.className ? node.props.className : ''
      const regex = /col(-[a-z]+)?-(\d+)/ig
      const matches = regex.exec(node_clases)
      current_group.push(node)
      if (matches) {
        const col = parseInt(matches.pop(), 10)
        count -= col
      }
      if (count <= 0 || idx === outer_visible.length - 1) {
        groups.push(<div key={`cols-${gidx}-${counter}`} className='input-group'>{current_group}</div>)
        current_group = []
        count = 12
        counter += 1
      }
    })
    return groups
  }

  render() {
    const { group, gidx, classes, card } = this.props
    let { groupname } = this.props
    // this.renders++
    if (this.props.modelname !== this.state.modelname) { return null }
    let hidden = false
    if (!this.containsEditable()) { hidden = true }
    if (this.props.webedit && !this.containsWebEdit()) { hidden = true }
    if (this.props.required && !this.containsRequired()) { hidden = true }
    if (this.props.quality && !this.containsQuality()) { hidden = true }
    if (groupname === 'Seller / Landlord Details' && this.props.form.values) {
      if (this.props.form.values.listing_type === 'For Sale') {
        groupname = 'Seller Details'
      } else if (this.props.form.values.listing_type === 'To Let') {
        groupname = 'Landlord Details'
      }
    }
    if (hidden) {
      return null
    }
    if (this.props.render) {
      return this.props.render({
        renderFieldGroup: this.renderFieldGroup,
        group,
        gidx,
        groupname,
        classes
      })
    }
    if (card !== false) {
      return (
        <Card
          key={`fgc-${gidx}`}
          id={this.props.id}
          background={true}
          collapsable={true}
          collapsed={this.props.collapsed}
          classes={classes} // So far only used for no padding on dropzones
          header={
            <h3>{groupname}</h3>
          }
          body={
            <div
              className={
                `editgroup${
                  this.state.required ? ' requiredonly' : ''}`
              }
            >
              { this.renderFieldGroup(group) }
            </div>
          }
        />
      )
    }
    return (
      <div
        className={classNames('editgroup', { requiredonly: this.state.required, qualityonly: this.state.quality }, classes)}
      >
        { this.renderFieldGroup(group) }
      </div>
    )
  }
}

FieldGroup.propTypes = {
  classes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),
  groupname: PropTypes.string,
  creator: PropTypes.string,
  group: PropTypes.object,
  render: PropTypes.func,
  gidx: PropTypes.number,
  required: PropTypes.bool,
  webedit: PropTypes.bool,
  quality: PropTypes.bool,
  collapsed: PropTypes.bool,
  config: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
  modelid: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  model: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  active_portals: PropTypes.array,
  actions: PropTypes.object,
  form: PropTypes.object,
  bulkedit: PropTypes.bool,
  fields: PropTypes.array,
  columns: PropTypes.bool,
  addons: PropTypes.array,
  card: PropTypes.bool,
  user: PropTypes.object,
  modelname: PropTypes.string,
  id: PropTypes.string,
  buttons: PropTypes.func
}


const mapStateToProps = (state, ownProps) => { // Pass only minimal data to the FieldGroup component
  let modelname = ownProps.modelname ? ownProps.modelname : null
  let modelid = ownProps.modelid ? ownProps.modelid : null

  if (ownProps.match && ownProps.match.params) {
    modelname = modelname ? modelname : ownProps.match.params.model
    modelid = modelid ? modelid : ownProps.match.params.id
  }

  const site = SITE(state)
  const minuser = FIELDGROUP_USER(state)
  const siteid = site.get('id')
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)
  const location = LOCATION(state)
  const selected = SELECTED(state, modelname)
  const addons = ADDONS(state)
  const config = modelname ? CONFIG(state, modelname) : ownProps.config
  const configs = CONFIGS(state)

  // Minimize cache
  let mincache = Map({ settings: Map({}) }) // We need to send only cache which field group needs
  mincache = mincache.mergeDeepIn([ 'settings', siteid ], settings)// We need settings for the current site
  mincache = mincache.set(`${modelname}`, Map({}))
  if (cache.get(modelname)) {
    if (ownProps.match && ownProps.match.params?.model !== 'settings') {
      mincache = mincache.mergeDeepIn([ modelname, modelid ], cache.getIn([ modelname, modelid ])) // We need the current model data in cache too
    } else { // Pass entire cache if no id
      mincache = mincache.mergeDeepIn([ modelname ], cache.get(modelname))
    }
  }
  ownProps.fields.forEach(field => {
    if (field.modelname) { mincache = mincache.set(field.modelname, cache.get(field.modelname)) }
    if (field.fields) { // Field array
      field.fields.forEach(fafield => {
        if (fafield.modelname) { mincache = mincache.set(fafield.modelname, cache.get(fafield.modelname)) }
      })
    }

    if (field.input === 'Associations') {
      const orig_modelname = ownProps.match ? ownProps.match.params.model : null
      const orig_modelid = ownProps.match ? ownProps.match.params.id : null
      field.associations = configs.getIn([ orig_modelname, 'associations' ]).toJS()
      field.recommendations = []
      if (orig_modelid) {
        field.recommendations = configs.getIn([ orig_modelname, 'fields' ])
          .filter(f => f.get('modelname') && configs.getIn([ orig_modelname, 'associations' ]).includes(f.get('modelname')))
          .map(f => ({ modelname: f.get('modelname'), value: cache.getIn([ orig_modelname, orig_modelid, f.get('name') ]) }))
      } else if (selected.has(orig_modelname) && selected.get(orig_modelname).count() === 1) {
        const model_id = selected.getIn([ orig_modelname, 0 ])
        field.recommendations = configs.getIn([ orig_modelname, 'fields' ])
          .filter(f => f.get('modelname') && configs.getIn([ orig_modelname, 'associations' ]).includes(f.get('modelname')))
          .map(f => ({ modelname: f.get('modelname'), value: cache.getIn([ orig_modelname, model_id, f.get('name') ]) }))
      }
      let recommendations = Map()
      field.recommendations.filter(rec => rec.value).forEach(rec => {
        let vals = List()
        if (recommendations.has(rec.modelname)) {
          if (List.isList(rec.value)) {
            vals = rec.value
          } else {
            vals = List([ rec.value ])
          }
        } else {
          recommendations = recommendations.set(rec.modelname, vals)
          if (List.isList(rec.value)) {
            vals = rec.value
          } else {
            vals = List([ rec.value ])
          }
        }
        recommendations = recommendations.set(rec.modelname, recommendations.get(rec.modelname).merge(vals))
      })
      field.recommendations = recommendations.keySeq().toArray().map(mname => {
        const value = recommendations.get(mname).toJS()
        return { modelname: mname, value }
      })
    }

    if (field.input === 'LocationSelect' || field.container === 'portals') { mincache = mincache.set('branches', cache.get('branches')) }

    if (field.caches) {
      field.caches.forEach(c => {
        if (!mincache.get(c)) {
          mincache = mincache.set(c, cache.get(c))
        }
      })
    }
  })


  if (cache && cache.getIn([ `${modelname}`, `${modelid}`, 'branch' ])) {
    // mincache = mincache.setIn([ 'branches', `${cache.getIn([ `${modelname}`, `${modelid}`, 'branch' ])}` ], cache.getIn([ 'branches', `${cache.getIn([ `${modelname}`, `${modelid}`, 'branch' ])}` ]))
    mincache = mincache.set('branches', cache.get('branches'))
  }

  return {
    cache: mincache,
    config,
    user: minuser,
    addons,
    modelname,
    location
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

const mergeProps = (stateProps, dispatchProps, ownProps) =>
  // need to collect actions from passed in props as well
  ({ ...ownProps, ...stateProps, actions: { ...dispatchProps.actions, ...ownProps.actions } })

const ConnectedFieldGroup = connect(
  mapStateToProps, mapDispatchToProps, mergeProps)(withImmutablePropsToJS(FieldGroup)
)

export default formikConnect(ConnectedFieldGroup)

