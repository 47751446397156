import PropTypes from 'prop-types'
import { getIn } from 'formik'
import React, { useState, useEffect } from 'react'


import SimpleTable from '../../simpletable/SimpleTable'
import { updateSearchParms, breakpoint } from '../../../../utils'
import { Button } from '../../../ui/Button'
import PageJump from '../../datatable/PageJump'
import Step from '../../Step'
import Card from '../../Card'
import QueryBuilder from '../../QueryBuilder'
import InlineSelect from './InlineSelect'


const RecipientsManager = props => {
  const {
    model,
    config,
    actions,
    user,
    deleteSelected
  } = props

  const qs = new QueryBuilder(location.search)

  const [ desktop ] = useState(breakpoint.matches)
  const [ term, setTerm ] = useState(getIn(qs, 'params.term', ''))
  const [ params ] = useState({ ...qs.getAllArgs(false), marketing_email: props.model.id })
  const [ searched, setSearched ] = useState(false)
  const [ searching, setSearching ] = useState(false)
  const [ results, setResults ] = useState({
    offset: getIn(qs, 'params.offset', 0),
    limit: getIn(qs, 'params.limit', 20),
    count: 0,
    recordsto: 0,
    recordsfrom: 0,
    next: null,
    previous: null
  })

  const isEnter = e => {
    if (e.keyCode === 13) { // fire goToPage on enter
      setSearching(true)
      setSearched(true)
      setTerm(e.target.value)
    } // continue typing
    return true
  }

  const updateInput = e => {
    if (searched) { setSearched(false) }
    setTerm(e.target.value)
  }

  const stepPage = dir => { // Step through result pages
    const { limit, count } = results
    const url = results[dir]
    let newoffset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { newoffset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        newoffset = Math.floor(count / limit) * limit
        break
      default:
        newoffset = 0
    }
    updateSearchParms('offset', newoffset)
  }

  const calculateRecords = response => {
    let from = 0
    let to = 0
    let newoffset = results.offset
    let newlimit = results.limit
    let newcount = results.count
    if (response.params) {
      newoffset = response.params.offset
      newlimit = response.params.limit
      newcount = Number(response.count)
      from = Number(newoffset) + 1 || 1
      to = (from - 1 + newlimit) > newcount ? newcount : from - 1 + newlimit
    }
    setResults(previousState => (
      {
        ...previousState,
        offset: newoffset,
        limit: newlimit,
        count: newcount,
        recordsto: to,
        recordsfrom: from,
        next: response.next,
        previous: response.previous
      }))
  }

  const fetchRecipients = ({ parms, signal, resolve, reject }) => {
    let p = {}
    if (parms) { p = { ...parms }}
    p.marketing_email = props.model.id
    p.order_by = '-created'
    actions.fetchMany({
      values: {
        modelname: 'email-recipients',
        modellist: true,
        params: p,
        signal
      },
      resolve,
      reject
    })
  }

  const changelimit = e => {
    const size = e.value
    sessionStorage.setItem('limit', size)
    updateSearchParms('limit', size)
  }

  const deleteRecipient = (refreshpage, recipient) => {
    deleteSelected(refreshpage, recipient)
  }

  useEffect(() => {
    if (searching) {
      updateSearchParms('path__contains', term)
      setSearching(false)
    }
  }, [ searching ])

  return (
    <Card
      id="recipients-simpletable"
      classes="grid-col-1"
      bodyclass="no-top-padding"
      background
      header={
        <>
          <div className="recipients-search search-fields">
            <div className="input-group keyword-search">
              <div className="form-group term">
                <div className="forminput">
                  <input
                    id="recipient-search"
                    // ref={el => { setRef(el) }}
                    type="search"
                    placeholder="Recipient Search"
                    className="form-control input-group-suffix"
                    onBlur={isEnter}
                    onKeyDown={isEnter}
                    onChange={updateInput}
                    value={term}
                  />
                  <Button icon="#icon24-Search" type="button" disabled={searching} onClick={() => {
                    setSearching(true)
                    setSearched(true)
                  }} className="input-group-addon btn btn-icon-16 btn-icon-left btn-none" />
                </div>
              </div>
              {searched &&
              <div className="reset-group">
                <Button
                  id="keyword-search-btn"
                  tabIndex="-1"
                  type="button"
                  onClick={() => {
                    setTerm('')
                    setSearched(false)
                    setSearching(true)
                  }}
                  disabled={searching}
                  className="input-group-addon btn btn-none"
                >
                  Reset Filters
                </Button>
              </div>
              }
            </div>
            <div className="page-tools">
              <PageJump
                params={{ offset: results.offset, limit: results.limit, count: results.count }}
                endpoint={getIn(config, 'endpoint')}
                modelname={getIn(config, 'modelname')}
                count={results.count}
              />

              <div className="page-buttons">
                <Step
                  stepPage={stepPage}
                  next={results.next}
                  previous={results.previous}
                />

                {desktop && <div className="record-count">
                  {results.recordsto ? results.recordsfrom : 0} - {results.recordsto} of {results.count}
                </div> }
                {desktop && <InlineSelect
                  id="field-limit"
                  name="limit"
                  className="inline-select meta-limit"
                  classNamePrefix="inline"
                  defaultValue={{ value: results.limit, label: results.limit.toString(10) }}
                  selectedValue={model.params ? Number(model.params.limit) : ''}
                  autosize={true}
                  options={[
                    { value: 20, label: '20' },
                    { value: 50, label: '50' },
                    { value: 100, label: '100' }
                  ]}
                  onChange={e => changelimit(e)}
                />}
              </div>
            </div>
          </div>
        </>
      }
      body={
        <div className="recipientsbody">
          <SimpleTable
            user={user}
            config={config}
            header={config.tableconfig.map(f => {
              const field = config.fields.find(fe => fe.name === f.name)
              return {
                ...field,
                truncate: false,
                input: null
              }
            })}
            action={({ parms, resolve }) => {
              new Promise((res1, rej1) => fetchRecipients({ parms, resolve: res1, reject: rej1 })).then(r => {
                calculateRecords(r)
                const rows = r.index.map(p => r.results[p])
                resolve({ options: rows })
              })
            }}
            params={params}
            parser={data => data}
            rowActions={(refreshpage, data) => {
              if (model.status !== 'Sent') {
                return (
                  <>
                    <Button icon="#icon16-Bin" className="btn btn-icon-16 btn-icon-only btn-none" onClick={() => deleteRecipient(refreshpage, data)} title="Delete Recipient" type="button" />
                  </>
                )
              }
              return null
            }}
          />
        </div>
      }
    />
  )
}

RecipientsManager.propTypes = {
  model: PropTypes.object,
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  cache: PropTypes.object,
  routeConfig: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  settings: PropTypes.object,
  deleteSelected: PropTypes.func
}

export default RecipientsManager
