/* eslint-disable no-unused-vars */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import isEqual from 'react-fast-compare'
import { NavLink, Redirect } from 'react-router-dom'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import { getIn as altGetIn, fromJS } from 'immutable'

import ContextMenu from '../../../containers/ContextMenu'
import { hasPermission, isConditional, parseURL, sortBy, valueFormat, slugify, title, useCustomCompareMemo, groupBy } from '../../../utils'
import FocusTrap from '../focus-trap/FocusTrap'
import TooltipContext from '../tooltips/TooltipContext'
import { Button } from '../../ui/Button'
import Checkbox from './Checkbox'


const Cell = props => {
  const { col, data, user, className, config, region, portals: site_portals, domains, ridx } = props

  const observer = useRef()
  const timeout = useRef()
  const dataRef = useCustomCompareMemo(data)
  const [ tooltip, setTooltip ] = useState(false)
  const [ { view, edit }, setVisibility ] = useState({ view: null, edit: null })
  const getModelStat = useCallback(() => {
    if (col.summands && Array.isArray(col.summands.sources)) {
      return getIn(data, [ 'meta', 'source_statistics' ], []).filter(stat => col.summands.sources.includes(stat.source)).map(stat => stat[col.summands.field]).reduce((prevVal, nextVal) => (prevVal += nextVal), 0) || 0
    }
    return getIn(data, [ 'meta', 'statistics', col.name ], 0)
  }, [ dataRef ])

  const getPortalValue = useCallback(() => {
    const key = col.name.split('.')
    const slug = key[1]
    const fname = key[2]
    const k = key.slice(2, key.length).join('.')
    const new_portal = getIn(data, 'meta.portals', []).find(p => p.meta.portal.slug === slug)
    if (new_portal) {
      if (fname === 'config') { return true }
      const global_idx = Object.keys(site_portals).find(p => site_portals[p].portal === new_portal.portal)
      return global_idx ? getIn(new_portal, k) : null
    }
    return null
  }, [ dataRef ])

  const getDomainValue = useCallback(() => {
    // eslint-disable-next-line no-unused-vars
    const [ meta, field, prop ] = col.name.split('.')
    if (getIn(data, field)) {
      switch (field) {
        case 'display_on_branch': {
          const domain = domains.find(d => d.branches?.includes(data.branch))
          const domain_statistics = data.domain_statistics.find(d => d.domain_id === domain.id)

          return getIn(domain_statistics, prop, 0)
        }
        case 'display_on_agent': {
          const domain = domains.find(d => d.agents?.includes(data.agent))
          const domain_statistics = data.domain_statistics.find(d => d.domain_id === domain.id)

          return getIn(domain_statistics, prop, 0)
        }
        case 'display_on_agent_2': {
          const domain = domains.find(d => d.agents?.includes(data.agent_2))
          const domain_statistics = data.domain_statistics.find(d => d.domain_id === domain.id)

          return getIn(domain_statistics, prop, 0)
        }
        case 'display_on_agent_3': {
          const domain = domains.find(d => d.agents?.includes(data.agent_3))
          const domain_statistics = data.domain_statistics.find(d => d.domain_id === domain.id)

          return getIn(domain_statistics, prop, 0)
        }
        case 'display_on_agent_4': {
          const domain = domains.find(d => d.agents?.includes(data.agent_4))
          const domain_statistics = data.domain_statistics.find(d => d.domain_id === domain.id)

          return getIn(domain_statistics, prop, 0)
        }
        default:
          return 0
      }
    }
    return 0
  }, [ dataRef ])

  const getValue = useCallback(() => {
    const viewing_feedback = data.meta?.viewing ? [
      getIn(data, 'meta.viewing.feedback_price') ? 'Price' : null,
      getIn(data, 'meta.viewing.feedback_location') ? 'Location' : null,
      getIn(data, 'meta.viewing.feedback_layout') ? 'Layout' : null,
      getIn(data, 'meta.viewing.feedback_size') ? 'Size' : null,
      getIn(data, 'meta.viewing.feedback_other') ? `Other-${getIn(data, 'meta.viewing.feedback_other')}` : null
    ].filter(n => n) : []

    const listing_model = [
      getIn(data, 'residential') ? 'Residential' : null,
      getIn(data, 'commercial') ? 'Commercial' : null,
      getIn(data, 'holiday') ? 'Holiday' : null,
      getIn(data, 'project') ? 'Project' : null
    ].filter(n => n)

    let new_value = ''
    let new_link = null
    let pconfig = null
    if (col.container) { // This col is contained within another store key (only used for stats now)
      if (col.container === 'stats') {
        new_value = getModelStat()
      } else if (col.container === 'portals') {
        new_value = getPortalValue()
        const pname = col.name.split('.')[1]
        pconfig = getIn(data, 'meta.portals', []).find(p => p.meta.portal.slug === pname)
      } else if (col.container === 'domain_statistics') {
        new_value = getDomainValue()
      } else {
        new_value = getIn(data, col.container) || ''
        if (Array.isArray(col.name)) {
          new_value = col.name.map(n => getIn(new_value, n)).join(col.labelseparator || ' ')
        }
        if (typeof new_value === 'object') {
          new_value = getIn(new_value, col.name) || ''
        }
      }
    } else if (col.modelname || col.metafield) {
      if (Array.isArray(col.name) && data.meta) {
        new_value = col.name.map(v => {
          let v_data = getIn(data.meta, v)
          if (!v_data && altGetIn(fromJS(data.meta), v.split('.'))) {
            v_data = altGetIn(fromJS(data.meta), v.split('.'))
          }
          v_data = Array.isArray(v_data) ? sortBy(v_data, col.optionlabel) : v_data
          return v_data
        }).join(col.labelseparator)
      } else if (data.meta) {
        const cvalue = getIn(data, col.name)
        let inner_data = cvalue

        if ((typeof cvalue === 'number') || (Array.isArray(cvalue) && cvalue.length > 0)) {
          inner_data = getIn(data.meta, col.name, getIn(data.meta, col.modelname))
        }

        if (!inner_data && altGetIn(fromJS(data.meta), col.name.split('.'))) {
          inner_data = altGetIn(fromJS(data.meta), col.name.split('.'))
        }
        if (![ null, undefined ].includes(inner_data)) {
          new_value = Array.isArray(inner_data) ? sortBy(inner_data, col.optionlabel) : inner_data
          if (new_value && getIn(new_value, `meta.${col.name}`) && Array.isArray(new_value.meta[col.name])) {
            // Below is specifically for agent syndication tab branch name - could be abstracted
            new_value = new_value.meta[col.name].find(x => x.id === data[col.metafield]).name
          }
          if (col.inner && col.inner.optionlabel) {
            if (Array.isArray(new_value) && Array.isArray(col.inner.optionlabel)) {
              new_value = new_value.map(v => col.inner.optionlabel.map(n => getIn(v, n)).join(col.labelseparator || ' '))
            } else {
              new_value = col.inner.optionlabel.map(n => getIn(new_value, n)).join(col.labelseparator || ' ')
            }
          }
        }
      }
    } else if (Array.isArray(col.name)) {
      new_value = col.name.map(name => {
        if ([ ' ', ', ' ].includes(name)) { return name }
        if (getIn(data, name)) {
          return getIn(data, name)
        }
        return null
      }).filter(a => a)
      if (![ null, undefined ].includes(col.labelseparator)) {
        new_value = new_value.join(col.labelseparator)
        if (col.title) {
          new_value = title(new_value)
        }
      } else {
        new_value = col.label === 'Concerns' ? viewing_feedback.join(', ') : new_value.filter(a => a).join(' ')
      }
    } else if (Array.isArray(col.coordinateFields)) {
      new_value = col.coordinateFields.map((name, idx) => {
        const field = config.field
        if (data.hasOwnProperty(name) && field) {
          if (data[name]) {
            return `${idx === 0 ? 'Lat' : 'Lng'}: ${data[name]}`
          }
        }
        return null
      }).filter(a => a).join(', ')
    } else if (col.value && data.hasOwnProperty(col.value)) {
      new_value = getIn(data, col.value) // field:value
    } else if (![ null, undefined ].includes(getIn(data, col.name))) {
      new_value = getIn(data, col.name) // field:value
    }
    try {
      if ([ null, undefined ].includes(new_value) && col.name && !Array.isArray(col.name) && altGetIn(fromJS(data), col.name.split('.'))) {
        new_value = altGetIn(fromJS(data), col.name.split('.'))
      }
      if (!new_value && col.name && Array.isArray(col.name) && !col.summands) {
        if (col.label === 'Concerns') {
          new_value = viewing_feedback.join(', ') || ''
        } else {
          new_value = col.name.map(v => altGetIn(fromJS(data), v.split('.'))).join(col.labelseparator || ' ')
        }
      }
    } catch (e) {
      new_value = null
    }
    let allowed = true
    if (col.permissions) {
      if (Array.isArray(new_value)) {
        new_value = new_value.filter(v => hasPermission(
          col.permissions,
          user.permissions,
          v,
          user.agent.id,
          col.permission_key
        ))
        if (!new_value.some(e => e).length) {
          allowed = false
        }
      } else {
        allowed = hasPermission(
          col.permissions,
          user.permissions,
          new_value,
          user.agent.id,
          col.permission_key
        )
      }
    }

    if (allowed && new_value && col.link) {
      new_link = parseURL(col.link, data, null, props.match)
    }

    if (col.optionlabel && ![ 'contact_popup', 'listing_popup' ].includes(col.format)) {
      if (new_value) {
        if (Array.isArray(col.optionlabel)) {
          if (Array.isArray(new_value)) {
            new_value = new_value.filter(v => ![ null, undefined ].includes(v)).map(v => col.optionlabel.map(f => getIn(v, f)).join(col.labelseparator || ' ')) // Join fields
          } else {
            new_value = col.optionlabel.map(f => new_value[f]).join(col.labelseparator || ' ')
          }
        } else if (Array.isArray(new_value)) {
          new_value = new_value.filter(v => ![ null, undefined ].includes(v)).map(v => getIn(v, col.optionlabel)) // Join fields
        } else {
          new_value = new_value && typeof new_value === 'object' ? new_value[col.optionlabel] : new_value
        }
      }
    }

    if (Array.isArray(new_value) && new_value.length) {
      if (col.truncate !== false) {
        if (typeof new_value[0] === 'object' && !Array.isArray(new_value[0])) {
          new_value = new_value[0]
        } else {
          new_value = `${new_value[0]}${(new_value.length > 1 && !col.format) ? ' ...' : ''}`
        }
      } else {
        new_value = new_value.join(col.labelseparator || ' ')
      }
    }

    if (col.format) {
      if (col.format === 'profile_photo') {
        new_value = {
          image: (data.meta && data.meta[col.name]) ? data.meta[col.name].file : null,
          full_name: `${data.first_name} ${data.last_name}`,
          initials: `${data.first_name.substring(0, 1)}${data.last_name.substring(0, 1)}`,
          profile_picture_coord_x: data.profile_picture_coord_x,
          profile_picture_coord_y: data.profile_picture_coord_y,
          profile_picture_width: data.profile_picture_width,
          profile_picture_height: data.profile_picture_height
        }
      }
      if (col.format === 'listing_popup' && data.web_ref) {
        new_value = data
      }
      if (col.format === 'listing_popup' && getIn(data, 'meta.listing.web_ref')) {
        new_value = getIn(data, 'meta.listing')
      }
      if (col.format === 'criteria') {
        new_value = data
      }
      if (col.format === 'next_show_date') {
        new_value = null
        if (data.on_show) {
          const show_days = getIn(data, 'on_show_events')
          const now = Date.now()
          let closest_date = now
          show_days.forEach(s => {
            const show_date = Date.parse(s.on_show_date)
            if (show_date > now && (show_date < closest_date || closest_date === now)) {
              closest_date = Date.parse(s.on_show_date)
              new_value = s.on_show_date
            }
          })
        }
      }
      if (col.format === 'contact_popup') {
        if (allowed && !getIn(data, 'props')) {
          if (getIn(data, 'meta.contact.email')) {
            new_value = getIn(data, 'meta.contact')
          } else if (getIn(data, 'email')) {
            new_value = data
          } else if (getIn(data, 'meta.owners')) {
            new_value = getIn(data, 'meta.owners')
            if (new_value && typeof new_value[0] === 'object' && !Array.isArray(new_value[0])) {
              new_value = new_value[0]
            }
          } else if (getIn(data, 'meta.owner')) {
            new_value = getIn(data, 'meta.owner')
          }
        } else if (col.optionlabel) {
          if (Array.isArray(col.optionlabel)) {
            if (Array.isArray(new_value)) {
              new_value = new_value.filter(v => ![ null, undefined ].includes(v)).map(v => col.optionlabel.map(f => getIn(v, f)).join(col.labelseparator || ' ')) // Join fields
            } else {
              new_value = col.optionlabel.map(f => new_value[f]).join(col.labelseparator || ' ')
            }
          } else if (Array.isArray(new_value)) {
            new_value = new_value.filter(v => ![ null, undefined ].includes(v)).map(v => getIn(v, col.optionlabel)) // Join fields
          } else {
            new_value = new_value && typeof new_value === 'object' ? new_value[col.optionlabel] : new_value
          }
        }
      }
      if (col.format === 'price_range') {
        const [ price_from, price_to ] = col.name
        new_value = [ getIn(data, price_from), getIn(data, price_to) ]
      }
      if (col.format === 'measurement_type') {
        col.measurement_type = data[`${col.name}_measurement_type`]
      }
      if (col.format === 'image') {
        new_value = getIn(new_value, 'meta.file', new_value)
      }
      if (col.format === 'feedlink') {
        if (col.name === 'feed_file') { new_value = data }
      }
      if (col.format === 'feedurl' && pconfig) {
        new_link = new_value ? valueFormat('feedurl', new_value, { portal: getIn(pconfig, 'meta.portal.slug'), location: getIn(data, 'meta.location'), listing_type: data.listing_type }) : ''
        if (pconfig.last_message && pconfig.last_message.indexOf('https://www.gumtree.co.za') !== -1) {
          const gumtree_url = pconfig.last_message.match(/(https:\/\/www.gumtree.co.za\/.+\d)/).pop()
          new_value = gumtree_url.split('/').pop()
          new_link = gumtree_url
        }
      }
      if (col.format === 'feedactive') {
        new_value = pconfig ? pconfig.active : false
        if (new_value && !getIn(data, 'feed_to_portals')) { new_value = false}
        if (new_value && !getIn(data, 'display_on_website')) { new_value = false}
        new_value = valueFormat('yesno', new_value, col)
      }
      if (col.format === 'feedstatus') {
        new_value = valueFormat(col.format, new_value, {
          pconfig: [ 'syndicationagents', 'syndicationbranches' ].includes(config.modelname) ? false : pconfig,
          location: getIn(data, 'meta.location'),
          listing_type: data.listing_type, ...col
        })
      }
      if (col.format === 'stage' || col.format === 'stage-expanded') {
        new_value = valueFormat(col.format, data, col)
      }
      if (![ 'feedurl', 'feedstatus', 'stage', 'stage-expanded' ].includes(col.format)) {
        if (col.format === 'contact_popup' && allowed) {
          new_value = valueFormat(col.format, new_value, col)
        } else if (col.format !== 'contact_popup') {
          new_value = valueFormat(col.format, new_value, col)
        }
      }
      if (col.format === 'absolute_url') {
        new_link = new_value ? parseURL(col.link, data, null, props.match) : ''
      }
      if (new_link && col.linklabel) {
        new_value = ![ 'null' ].includes(new_link) ? parseURL(col.linklabel, data, null, props.match) : ''
      }
      if ([ 'contact_whatsapp_link', 'whatsapp_link_only' ].includes(col.format)) {
        col.modelname = config.modelname
        new_value = valueFormat(col.format, data, col)
      }
    }

    if (col.format === 'link' && col.name === 'video_id') {
      new_value = valueFormat(col.format, data, col)
    }
    if (
      new_value && typeof new_value !== 'string' && (
        (!Array.isArray(new_value) && !React.isValidElement(new_value))
        || (Array.isArray(new_value) && !new_value.every(e => React.isValidElement(e)))
      )
    ) {
      new_value = new_value.toString()
    }

    if (new_value && new_value.toString().includes('[Redacted]')) {
      new_value = '[Redacted]'
    }
    if (col.name === 'idx') {
      new_value = valueFormat('number', ridx + 1)
    }
    if (col.format === 'choice' && col.name === 'listing_model') {
      new_value = listing_model ? listing_model[0] : ''
    }

    return { value: new_value, portal: pconfig, link: new_link }
  }, [ dataRef ])

  const [ { value, link, portal }, setData ] = useState(getValue())

  const checkContent = useCallback(element => {
    if (element && element.childNodes.length) {
      const innerWidth = Math.ceil(element.childNodes[0].offsetWidth)
      const outerWidth = Math.ceil(element.offsetWidth || 0) - 16
      clearTimeout(timeout.current)
      if (innerWidth > outerWidth) {
        timeout.current = setTimeout(() => {
          setTooltip(true)
        }, 100)
      } else if (innerWidth <= outerWidth && tooltip) {
        timeout.current = setTimeout(() => {
          setTooltip(false)
        }, 100)
      }
    }
  }, [])

  const checkPermissions = useCallback(() => {
    if (!user) {
      if (col.edit !== edit) {
        setVisibility({ view: true, edit: col.edit })
      }
    } else {
      let new_view
      let new_edit
      if (col.permissions) {
        if (Array.isArray(value)) {
          new_view = value.some(v =>
            hasPermission(col.permissions, user.permissions, v, user.agent.id, col.permission_key)
          )
        } else {
          new_view = hasPermission(col.permissions, user.permissions, value, user.agent.id, col.permission_key)
        }
      } else {
        new_view = region === 'ae' ? false : true
      }
      if (col.read && !isConditional(col, 'read', false, {
        values: Array.isArray(data.meta[col.name]) ? data.meta[col.name][0] : data.meta[col.name]
      }, user)) {
        new_view = false
      }
      if (col.table_edit && col.edit && [ 'Check', 'Select' ].includes(col.input)) {
        new_edit = isConditional(col, 'edit', false, { values: data, initialValues: data }, user)
      } else {
        new_edit = false
      }
      if (config.modelname === 'referrals' && [ 'Scheduled', 'Pending', 'Declined' ].includes(data.status) && user.agent.id === data.recipient_agent) {
        new_view = false
      }
      if (new_view !== view || new_edit !== edit) {
        setVisibility({ view: new_view, edit: new_edit })
      }
    }
  }, [ dataRef, useCustomCompareMemo(col), useCustomCompareMemo(user), edit, view ])


  const getIndicator = useCallback(column => {
    if (column.indicator) {
      const val = column.indicator.value
      switch (column.indicator.type) {
        case 'range': {
          const values = Array.isArray(column.name) ? (
            column.name.map(v => parseInt(getIn(data, v), 10))
          ) : [ getIn(data, column.name), 0 ]
          const [ min, max ] = values
          let indicate = false
          if (min && max) {
            indicate = val <= max && val >= min
          }
          if (min && !max) {
            indicate = val >= min
          }
          if (!min && max) {
            indicate = val <= max
          }
          if (!min && !max) {
            indicate = true
          }
          return indicate ? <svg className="indicator"><use href="/images/icons-16.svg#icon16-Check" /></svg> : null
        }
        default: { // in
          const values = Array.isArray(column.name) ? column.name.map(v => getIn(data, v)) : getIn(data, column.name)
          const indicate = values ? values.includes(val) : true
          return indicate ? <svg className="indicator"><use href="/images/icons-16.svg#icon16-Check" /></svg> : null
        }
      }
    }
    return null
  }, [])

  const setupEl = useCallback(el => {
    if (el) {
      checkContent(el)
      observer.current?.observe(el)
    }
  })

  useEffect(() => {
    observer.current = new ResizeObserver(entries => {
      for (const entry of entries) {
        checkContent(entry.target)
      }
    })
    checkPermissions()
    return () => {
      observer.current.disconnect()
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    checkPermissions()
    setData(getValue())
  }, [ dataRef ])

  if ([ null, undefined ].includes(value)) {
    return (<td><div className="tablecell"></div></td>)
  }
  if (edit && col.input) {
    return (
      <td className={className ? className : 'text-center'}>
        <div className="tablecell">
          <Checkbox
            val={data.id}
            name={col.name}
            selected={data[col.name]}
            endpoint={config.endpoint}
            modelname={config.modelname}
            clickAction={props.updateModel}
            promise={true}
          />
        </div>
      </td>
    )
  }

  const text = <span>{col.empty_value && !value ? col.empty_value : value}</span>
  const indicator = col.indicator ? getIndicator(col) : null
  let inner = null
  if (view && link) {
    if (link.startsWith('http')) {
      inner = (
        <>
          {indicator}
          <Button component="a" href={link} target="_blank" className="has-link">{text}</Button>
        </>
      )
    } else {
      inner = (
        <>
          {indicator}
          <Button component={NavLink} to={link} className="has-link">{text}</Button>
        </>
      )
    }
  } else {
    inner = <>
      {indicator}
      {text}
    </>
  }

  if (col.tooltip && portal && portal[col.tooltip]) {
    return (
      <td className={className}>
        <TooltipContext
          options={{ followCursor: false, arrow: 'top', shiftY: 8 }}
          className="heading-wrapper"
          content={portal[col.tooltip]}
        >
          <div className="tablecell" ref={setupEl}>{inner}</div>
        </TooltipContext>
      </td>
    )
  }

  if (tooltip) {
    return (
      <td className={className}>
        <TooltipContext
          options={{ followCursor: false, arrow: 'bottom', shiftY: -8 }}
          className="cell-wrapper"
          content={value}
        >
          <div className="tablecell" ref={setupEl}>{inner}</div>
        </TooltipContext>
      </td>
    )
  }

  if (!view && col.tablehide) {
    return (<td><div className="tablecell"></div></td>)
  }

  return (
    <td className={className} style={col.cellStyle}>
      <div className="tablecell" ref={setupEl} style={col.style}>
        {inner}
      </div>
    </td>
  )
}

Cell.propTypes = {
  col: PropTypes.object,
  data: PropTypes.object,
  config: PropTypes.object,
  value: PropTypes.any,
  user: PropTypes.object,
  portals: PropTypes.object,
  match: PropTypes.object,
  region: PropTypes.string,
  className: PropTypes.string,
  updateModel: PropTypes.func,
  domains: PropTypes.array,
  model: PropTypes.object,
  ridx: PropTypes.number
}

const Row = props => {
  const { row, currency, user, portals, domains, config, sortable, ridx, refreshPage } = props

  const DragHandle = sortable ? listeners => <i className="dz-handle" {...listeners} /> : null

  const [ open, setOpen ] = useState(false)
  const [ redirect, setRedirect ] = useState(null)
  const [ menuStyles, setMenuStyles ] = useState(null)
  const [ fields, setFields ] = useState(groupBy(config.fields.filter(f => f.name), null, (val, item) => (
    `${Array.isArray(item.name) ? JSON.stringify(item.name) : item.name}${item.verb ? `__${item.verb}` : ''}`
  )))
  const root = useRef(document.getElementById('wrapper'))
  const ref = useRef()
  const dropdown = useRef()
  let clicks = 2

  const handleClose = () => {
    setOpen(false)
  }

  const setPosition = e => {
    let cursorX = e.pageX
    const cursorY = e.pageY
    if (e.pageX + 145 > window.innerWidth) {
      cursorX = window.innerWidth - 160
    }
    const updatedStyles = { top: cursorY, left: cursorX, position: 'absolute', display: 'block' }
    return updatedStyles
  }

  const setSubPosition = el => {
    if (!el) { return {} }
    const box = el.getBoundingClientRect()
    const newbox = {
      x: box.x,
      y: box.y,
      bottom: box.bottom + document.scrollingElement.scrollTop,
      top: box.top,
      left: box.left,
      right: box.right,
      width: box.right - box.left
    }
    let cursorX = '100%'
    let updatedStyles = { top: 0, left: cursorX, position: 'absolute', display: 'block' }
    if (newbox.left + newbox.width + newbox.width > window.innerWidth) {
      cursorX = newbox.width
      updatedStyles = { top: 0, right: cursorX, left: 'auto', position: 'absolute', display: 'block' }
    }
    return updatedStyles
  }

  const isSelected = id => {
    if (props.selectable && props.selected) {
      return props.selected.indexOf(id) !== -1
    }
    return false
  }

  const singleClick = () => {
    props.selectOne({
      modelname: props.config.modelname,
      id: props.row.id,
      select: !isSelected(props.row.id)
    })
  }

  const doubleClick = () => {
    if (props.config.doubleclick) {
      const url = parseURL(props.config.doubleclick, props.row, null, props.match)
      setRedirect(url)
    } else {
      setRedirect(`/secure/${props.row.site}/${props.config.modelname}/${props.row?.id}`)
    }
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform
  } = sortable ? useSortable({ id: row?.id }) : [ null, null, null, null ]
  const style = sortable ? {
    transform: CSS.Translate.toString(transform)
  } : {}
  if (!row) { return null }
  const selected = isSelected(row.id)
  if (redirect) { return (<Redirect to={redirect} push />) }
  const inner = []
  if (sortable) {
    const sort = (
      <td key='sort' className="column-sort">
        <DragHandle {...listeners} />
      </td>
    )
    inner.push(sort)
  }
  if (props.selectable) {
    const checkbox = (
      <td key='check' className='text-center column-check'>
        <Checkbox
          key={`check-${row.id}`}
          val={row.id}
          name="select"
          selected={selected}
          endpoint={props.config.endpoint}
          modelname={props.config.modelname}
          clickAction={props.selectOne}
        />
      </td>
    )
    inner.push(checkbox)
  }
  // Loop over table columns
  if (props.tableconfig) {
    props.tableconfig.forEach((col, cidx) => {
      let colname = col.name
      if (typeof col.name !== 'string') { colname = JSON.stringify(col.name) }
      colname = `${colname}${col.verb ? `__${col.verb}` : ''}`
      if ('children' in col) {
        col.children.forEach((col2, cidx2) => {
          let col2name = col2.name
          if (typeof col2.name !== 'string') { col2name = JSON.stringify(col2.name) }
          col2name = `${col2name}${col2.verb ? `__${col2.verb}` : ''}`
          inner.push(
            <Cell
              key={`${col2.name}-${cidx}-${cidx2}`}
              user={user}
              config={{
                modelname: config.modelname,
                field: getIn(fields, col2name),
                endpoint: config.endpoint
              }}
              portals={portals}
              domains={domains}
              match={props.match}
              updateModel={props.updateModel}
              className={classNames(`column-${slugify(col2name)}`, col2.classes)}
              ridx={ridx}
              col={{
                ...col2,
                currency: [ 'currency', 'currencyabbr', 'price_range', 'listing_popup' ].includes(col2.format) ? currency : null
              }}
              data={row}
              model={props.model}
            />
          ) // Row is model data, fld is field config
        })
      } else {
        inner.push(
          <Cell
            key={`${col.name}-${cidx}`}
            user={user}
            config={{
              modelname: config.modelname,
              field: getIn(fields, col.name),
              endpoint: config.endpoint
            }}
            portals={portals}
            domains={domains}
            match={props.match}
            updateModel={props.updateModel}
            className={classNames(`column-${slugify(colname)}`, col.classes)}
            ridx={ridx}
            col={{
              ...col,
              currency: [ 'currency', 'currencyabbr', 'price_range', 'listing_popup' ].includes(col.format) ? currency : null
            }}
            data={row}
            model={props.model}
          />
        ) // Row is model data, fld is field config
      }
    })
    if (props.rowActions) {
      inner.push(
        <td key='actions' className='column-actions'>
          <div>
            {props.rowActions(refreshPage, row)}
          </div>
        </td>
      )
    }
  }
  const classes = []
  if (selected) { classes.push('selected') }
  let firing = false
  return (
    <tr
      ref={el => {
        if (sortable) { setNodeRef(el) }
        ref.current = el
      }}
      {...(sortable && attributes)}
      style={style}
      onClick={e => {
        if (
          (!ref.current.contains(e.target) ||
            e.target.classList.contains('has-link') ||
            e.target.parentElement.classList.contains('checkcontainer'))
            && !e.shiftKey
        ) {
          clearTimeout(firing)
          e.stopPropagation()
          return e
        }
        clicks -= 1
        firing = setTimeout(() => {
          if (clicks <= 1) {
            singleClick()
          } else {
            clicks = 2
          }
        }, 100)
        return e
      }}
      onDoubleClick={props.doubleClick ? e => {
        if (!ref.current.contains(e.target) ||
            e.target.classList.contains('has-link') ||
            e.target.parentElement.classList.contains('checkcontainer')
        ) { return e }
        clearTimeout(firing)
        clicks = 2
        return doubleClick()
      } : null}
      onContextMenu={props.contextMenu ? e => {
        e.preventDefault()
        setTimeout(() => {
          setOpen(true)
          setMenuStyles(setPosition(e, ref))
        })
      } : null}
      className={classes.join(' ')}
    >
      {inner}
      {open && ReactDOM.createPortal(
        <FocusTrap
          key="focus"
          ref={dropdown}
          onExit={handleClose}
          className="context-menu-cover"
          dialog={true}
        >
          <div style={menuStyles} className="nav action-list">
            <ContextMenu
              parent="context-menu"
              user={user}
              modelid={row.id}
              redirectSchema={props.redirectSchema}
              dropdownPositionCallback={setSubPosition.bind(this)}
              dropdownHover
              modelname={props.config.modelname}
            />
          </div>
        </FocusTrap>,
        root.current
      )}
    </tr>
  )
}

Row.propTypes = {
  row: PropTypes.object,
  config: PropTypes.object,
  columns: PropTypes.object,
  redirectSchema: PropTypes.string,
  tableconfig: PropTypes.array,
  selectOne: PropTypes.func,
  user: PropTypes.object,
  domains: PropTypes.array,
  portals: PropTypes.object,
  selected: PropTypes.array,
  selectable: PropTypes.bool,
  currency: PropTypes.string,
  updateModel: PropTypes.func,
  rowActions: PropTypes.func,
  doubleClick: PropTypes.bool,
  contextMenu: PropTypes.bool,
  sortable: PropTypes.bool,
  model: PropTypes.object,
  modelname: PropTypes.string,
  ridx: PropTypes.number,
  match: PropTypes.object,
  refreshPage: PropTypes.func
}

export default Row
