import PropTypes from 'prop-types'
import React from 'react'
import isEqual from 'react-fast-compare'

import TableManager from '../../../containers/TableManager'
import { capitalize, isConditional, logEvent, updateSearchParms, breakpoint } from '../../../utils'
import { Button } from '../../ui/Button'
import InlineSelect from '../forms/inputs/InlineSelect'
import AsyncInlineSelect from '../forms/inputs/AsyncInlineSelect'
import Step from '../Step'
import PageJump from './PageJump'


class Meta extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      statusname: '',
      status: {},
      statuses: [],
      domainname: '',
      domain: {},
      domains: [],
      domainfield: null,
      limit: 20,
      desktop: breakpoint.matches
    }
    this.isConditional = isConditional.bind(this)
    this.stepPage = this.stepPage.bind(this)
    this.toggleDesktop = this.toggleDesktop.bind(this)
    this.initStatuses = this.initStatuses.bind(this)
    this.initDomains = this.initDomains.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    this.changeDomain = this.changeDomain.bind(this)
  }

  componentDidMount() {
    breakpoint.addEventListener('change', this.toggleDesktop)
    const limit = sessionStorage.getItem('limit')
    if (limit) { this.setState({ limit }) }
    this.initStatuses()
    this.initDomains()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.config.modelname !== this.props.config.modelname) {
      this.initStatuses()
      this.initDomains()
    } // Model change
    if (prevProps.params !== this.props.params) { // Params have changed
      const field = this.props.findStatus()
      if (field) {
        const fieldname = `${field.name}__in`
        if (this.props.params[fieldname] !== this.state.status.value) { // Check for status variance ie. on page nav backwards
          let status = this.state.statuses.find(o => typeof this.props.params[fieldname] !== 'undefined' && o.value.toString() === this.props.params[fieldname].toString())
          if (typeof this.props.params[fieldname] === 'undefined') { status = this.state.statuses.find(o => o.value === '') }
          if (status && this.state.status !== status) { this.setState({ status: status }) }
        }
      }
      const domainfield = this.props.findDomain()
      if (domainfield) {
        const fieldname = `${domainfield.name}__in`
        if (this.props.params[fieldname] !== this.state.domain.value) { // Check for status variance ie. on page nav backwards
          let domain = this.state.domains.find(o => typeof this.props.params[fieldname] !== 'undefined' && o.value.toString() === this.props.params[fieldname].toString())
          if (typeof this.props.params[fieldname] === 'undefined') { domain = this.state.domains.find(o => o.value === '') }
          if (domain && !isEqual(this.state.domain, domain)) { this.setState({ domain: domain, domainfield }) }
        }
      }
    }
  }

  componentWillUnmount() {
    breakpoint.removeEventListener('change', this.toggleDesktop)
  }

  toggleDesktop(e) {
    if (e.matches && !this.state.desktop) {
      this.setState({ desktop: true })
    } else if (!e.matches && this.state.desktop) {
      this.setState({ desktop: false })
    }
  }

  stepPage(dir) { // Step through result pages
    const { count, params } = this.props.model
    const { limit } = params
    const url = this.props.model[dir]
    let offset = 0
    switch (dir) {
      case 'next':
      case 'previous':
        if (url && url.match(/offset=([^&]*)/)) { offset = url.match(/offset=([^&]*)/)[1] }
        break
      case 'last':
        offset = Math.floor(count / limit) * limit
        // fix offset when there's no remainder
        if (offset === count) {
          offset -= limit
        }
        break
      default:
        offset = 0
    }
    if (this.props.noquery) {
      // eslint-disable-next-line no-console
      console.log(this.props.model.params)
    } else {
      updateSearchParms('offset', offset)
    }
    logEvent('PAGE_STEP', { modelname: this.props.config.modelname, offset, limit })
  }

  initStatuses() { // Below sets the initial status options for the model
    let statuses = [ { value: '', label: 'All' } ]
    const field = this.props.findStatus()
    if (!field) { return }
    const fieldname = `${field.name}__in`
    const form = {}
    form[fieldname] = field.defaultValue
    if (field && field.options) { statuses = [ ...statuses, ...field.options.filter(o => this.isConditional(o, 'show', true, form)) ] } // Include All
    statuses = statuses.map(s => {
      if (s.value === true) { return { ...s, value: 1 } }
      if (s.value === false) { return { ...s, value: 0 } }
      return { ...s }
    })
    let status = statuses.find(o => (
      this.props.params[fieldname] && o.value.toString() === this.props.params[fieldname].toString()
    ))
    if (!status) { status = statuses.find(o => o.value === '') }
    this.setState({
      statusname: fieldname,
      status: status,
      statuses: statuses
    })
  }

  initDomains() { // Below sets the initial status options for the model
    let domains = [ { value: '', label: 'All', id: '', domain: 'All' } ]
    const field = this.props.findDomain()
    if (!field) {
      return
    }
    const fieldname = `${field.name}__in`
    const form = {}
    form[fieldname] = field.defaultValue
    if (field && field.options) { domains = [ ...domains, ...field.options.filter(o => this.isConditional(o, 'show', true, form)) ] } // Include All
    let domain = domains.find(o => (
      this.props.params[fieldname] && o.value.toString() === this.props.params[fieldname].toString()
    ))
    if (!domain) { domain = domains.find(o => o.value === '') }
    this.setState({
      domainname: fieldname,
      domain: domain,
      domains: domains,
      domainfield: field
    })
  }

  changeStatus(e) { // Change the status filter on the page model
    this.setState({ status: e })
    const field = this.props.findStatus()
    updateSearchParms(`${field.name}__in`, e.value)
    logEvent('SELECT_STATUS', { modelname: this.props.config.modelname, status: e.value })
  }

  changeDomain(e) { // Change the status filter on the page model
    if ([ null, undefined ].includes(e.value)) {
      return
    }
    this.setState({ domain: e })
    const field = this.props.findDomain()
    updateSearchParms(`${field.name}__in`, e.value)
    logEvent('SELECT_DOMAIN', { modelname: this.props.config.modelname, domain: e })
  }

  changelimit(e) {
    const size = e.value
    sessionStorage.setItem('limit', size)
    updateSearchParms('limit', size)
    logEvent('CHANGE_PAGE_SIZE', { modelname: this.props.config.modelname, pagesize: size })
  }

  calculateRecords() {
    let from = 0
    let to = 0
    if (this.props.params) {
      const { offset, limit } = this.props.params
      const count = Number(this.props.count)
      from = Number(offset) + 1 || 1
      to = (from - 1 + limit) > count ? count : from - 1 + limit
    }
    return { from, to }
  }

  render() {
    const records = this.calculateRecords()
    const { selectedRecords } = this.props
    const strlimit = this.state.limit.toString(10)
    let status_label = [ 'residential', 'commercial', 'holiday', 'projects' ].includes(this.props.config.modelname) ? 'Listing Status' : `${capitalize(this.props.config.singular)} Status`
    status_label = <div className="input-group-addon">{status_label}:</div>
    return (
      <div className="tablemeta">
        {this.state.statusname && !this.props.advanced &&
          <InlineSelect
            id={`field-${this.state.statusname}`}
            name={this.state.statusname}
            prefix={status_label}
            className="inline-select meta-status"
            classNamePrefix="inline"
            defaultValue={this.state.status}
            autosize={true}
            onChange={e => this.changeStatus(e)}
            options={this.state.statuses}
          />
        }
        {
          selectedRecords && selectedRecords.length ? <div className="selected-records"><span>{`${selectedRecords.length} ${selectedRecords.length > 1 ? 'Records' : 'Record'} Selected`}</span></div> : ''
        }
        {this.state.domainname && !this.props.advanced &&
          <AsyncInlineSelect
            id={`field-${this.state.domainname}`}
            name={this.state.domainname}
            prefix={<div className="input-group-addon">Domain</div>}
            className="inline-select meta-status"
            classNamePrefix="inline"
            fetchMany={this.props.fetchMany}
            defaultValue={this.state.domain}
            modelname="domains"
            autosize={true}
            onChange={this.changeDomain}
            optionlabel={this.state.domainfield.optionlabel}
            noclear
            form={{}}
            field={{
              value: this.state.domain.value
            }}
            options={this.state.domains}
          />
        }

        <div className="page-tools">
          <PageJump
            params={this.props.params}
            endpoint={this.props.config.endpoint}
            modelname={this.props.config.modelname}
            count={this.props.count}
          />

          <div className="page-buttons">
            <Step
              stepPage={this.stepPage}
              next={this.props.next}
              previous={this.props.previous}
            />

            {this.state.desktop && <div className="record-count">
              {records.to ? records.from : 0} - {records.to} of {this.props.count}
            </div> }
            {this.state.desktop && <InlineSelect
              id="field-limit"
              name="limit"
              className="inline-select meta-limit"
              classNamePrefix="inline"
              defaultValue={{ value: this.state.limit, label: strlimit }}
              selectedValue={(this.props.params) ? Number(this.props.params.limit) : ''}
              autosize={true}
              options={[
                { value: 20, label: '20' },
                { value: 50, label: '50' },
                { value: 100, label: '100' }
              ]}
              onChange={e => this.changelimit(e, this.props.params)}
            />}

            {this.props.toggleManager &&
            <div className="table-manager">
              <Button icon="#icon16-Columns" className="btn btn-subtle btn-icon-16 btn-table-manager" onClick={this.props.toggleManager} />
              <TableManager match={this.props.match} modelname={this.props.modelname} />
            </div>
            }
          </div>
        </div>

      </div>
    )
  }
}


Meta.propTypes = {
  advanced: PropTypes.bool,
  config: PropTypes.object,
  match: PropTypes.object,
  count: PropTypes.number,
  modelname: PropTypes.string,
  next: PropTypes.string,
  params: PropTypes.object,
  cache: PropTypes.object.isRequired,
  previous: PropTypes.string,
  toggleManager: PropTypes.func,
  findStatus: PropTypes.func,
  findDomain: PropTypes.func,
  fetchMany: PropTypes.func,
  model: PropTypes.object,
  selectedRecords: PropTypes.array,
  noquery: PropTypes.bool
}

export default Meta
