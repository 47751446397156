/* eslint-disable new-cap */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Map } from 'immutable'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { toggleWideSidebar, createModel, fetchMany, notifyUser, selectOne, selectNone } from '../actions'
import { CONFIG, MINUSER, CONFIGS, CACHE, MODEL, PREFERENCES, SELECTED } from '../selectors'
import AssetSelectSidebar from '../components/AssetSelectSidebar'


const AssetSelectSidebarContainer = props => <AssetSelectSidebar { ...props } />

AssetSelectSidebarContainer.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  ui: PropTypes.object,
  app: PropTypes.object,
  actions: PropTypes.object,
  site: PropTypes.object,
  model: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  cache: PropTypes.object,
  modelname: PropTypes.string,
  portals: PropTypes.object,
  settings: PropTypes.object,
  addSelected: PropTypes.func
}


function mapStateToProps(state, ownProps) {
  const cache = CACHE(state)
  const preferences = PREFERENCES(state, ownProps.modelname)
  let meta_cache = Map({ settings: {} })
  meta_cache = meta_cache.set('settings', cache.get('settings'))
  return {
    user: MINUSER(state),
    sidebar: state.getIn([ 'ui', 'sidebar' ]),
    cache,
    meta_cache,
    config: CONFIG(state, ownProps.modelname),
    configs: CONFIGS(state),
    model: MODEL(state, ownProps.modelname),
    tableconfig: Map({ column_preferences: preferences ? preferences : Map({}) }),
    selected: SELECTED(state, ownProps.modelname)
  }
}

const mapDispachToProps = dispatch => ({
  actions: bindActionCreators({
    toggleWideSidebar,
    createModel,
    fetchMany,
    notifyUser,
    selectOne,
    selectNone
  }, dispatch) })


export default connect(mapStateToProps, mapDispachToProps)(withImmutablePropsToJS(AssetSelectSidebarContainer))
